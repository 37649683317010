import React from "react";
import styled from "styled-components";
import ErrorForm from "./ErrorForm";
import { Heading5, Heading6 } from "./texts/textStyles";
import Icon from "./Icon";

function TextAreaInput(
  { text,
    type = "textarea",
    icon,
    placeholder,
    error,
    input,
    rows = 5,
    onChange,
    disabled, }) {
  return (
    <Wrapper>
      <Title>{text}</Title>
      <Box disabled={disabled}>
        {icon && <Icon name={icon} size="24px" />}
        <InputField
          type={type}
          placeholder={placeholder}
          rows={rows}
          value={input}
          onChange={(e) => onChange(e.target.value)}
        />
      </Box>
      {error && <ErrorForm error={error} />}
    </Wrapper>
  );
}

const Wrapper = styled(Heading5)`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const Title = styled(Heading6)`
  text-transform: uppercase;

  /* Secondary Text */
  color: #717579;
`;

const Box = styled(Heading5)`
  border: 1px solid #d7d7d7;
  border-radius: 14px;
  padding: 7px 22px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  color: #303134;
  fill: #717579;
  align-items: center;
  background-color: ${({ disabled }) => (disabled ? "#EEEEEE" : "none")};
  &:focus {
    border: 1px solid black;
  }
`;

const InputField = styled.textarea`
  -webkit-background-clip: text;
  width: 100%;
  border: none;
  font: inherit;

  /* Primary Text */
  color: #303134;

  &:focus {
    outline: none;
  }
`;
export default TextAreaInput;
