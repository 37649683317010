import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { useAuth } from "../../../providers/AuthProvider";
import ButtonContained from "../../../libs/reuse/components/ButtonContained";
import { useModals } from "../../../providers/ModalsProvider";
import Navigation from "../../../page/Header/components/Navigation";
import { useTranslation } from "react-i18next";

function HamburgerMenu() {
  const { user } = useAuth();
  const { closeHeaderModal } = useModals();
  const navigate = useNavigate();
  const { t } = useTranslation("headerModale");

  return (
    <Wrapper onClick={(e) => e.stopPropagation()}>
      <Navigation />
      {!user &&
        <Additional>
          <ButtonContained
            text={t("raceIn")}
            color="#6689A1"
            onClick={() => {
              navigate("/login")
              closeHeaderModal();
            }}
          />
        </Additional>
      }
    </Wrapper>
  );
}

const Wrapper = styled.div`
  top: 0px;
  right: 50px;
  position: absolute;
  padding: 30px 45px;
  background: #ffffff;
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.52);
  border-radius: 0px 0px 20px 20px;
  display: none;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 340px;
  box-sizing: border-box;

  @media (max-width: 1600px) {
    right: 35px;
  }

  @media (max-width: 1200px) {
    right: 20px;
  }

  @media (max-width: 960px) {
    display: flex;
    width: 100%;
    right: 0px;
    align-items: center;
    justify-content: center;
    padding: 30px 30px;
  }

  @media (max-width: 480px) {
    padding: 15px 20px;
  }
`;

const Additional = styled.div`
  flex-grow: 1;
  gap: 20px;
  display: flex;
  justify-content: end;
`;

export default HamburgerMenu;
