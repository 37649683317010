import "firebase/firestore";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  or,
  and,
  documentId,
  updateDoc,
  doc,
  deleteDoc,
  Timestamp,
  orderBy,
  limit,
} from "firebase/firestore";
import { db } from "../../../firebase-config";

export const addEvent = async (event) => {
  await addDoc(collection(db, "events"), event);
};

export const getAllEvents = async () => {
  return await getDocs(collection(db, "events"));
};

export const getAllEventsQuery = () => {
  const q = query(collection(db, "events"));
  return q;
};

export const getAllEventsPerMonth = async (startDate, endDate) => {
  const q = query(
    collection(db, "events"),
    and(
      or(
        where("startDate", ">=", startDate),
        where("endDate", ">=", startDate)
      ),
      or(where("endDate", "<=", endDate), where("endDate", "<=", endDate))
    )
  );
  return await getDocs(q);
};

export const getAllEventsPerMonthQuery = (startDate, endDate) => {
  const q = query(
    collection(db, "events"),
    and(
      or(
        where("startDate", ">=", startDate),
        where("endDate", ">=", startDate)
      ),
      or(where("endDate", "<=", endDate), where("endDate", "<=", endDate))
    )
  );
  return q;
};

export const getAllEventsPerDay = async (date) => {
  const q = query(
    collection(db, "events"),
    and(where("startDate", "<=", date), where("endDate", ">=", date))
  );
  return await getDocs(q);
};

export const getAllEventsPerDateQuery = (date) => {
  const q = query(
    collection(db, "events"),
    and(where("startDate", "<=", date), where("endDate", ">=", date))
  );
  return q;
};

export const getUpcommingEvents = async (numberOfEvents) => {
  const q = query(
    collection(db, "events"),
    where("startDate", ">=", Timestamp.now()),
    orderBy("startDate"),
    limit(numberOfEvents || 5)
  );
  return await getDocs(q);
};

export const getAllPinnedEvents = async () => {
  const q = query(
    collection(db, "events"),
    where("isPinned", "===", true),
    orderBy("startDate")
  );
  return await getDocs(q);
};

export const getEventByIdQuery = (id) => {
  const q = query(collection(db, "events"), where(documentId(), "==", id));
  return q;
};

export const getEventById = async (id) => {
  return await getDocs(getEventByIdQuery(id));
};

export const updateEventById = async (id, event) => {
  try {
    await updateDoc(doc(db, "events", id), event);
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};

export const deleteEventById = async (id) => {
  try {
    await deleteDoc(doc(db, "events", id));
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};

export const searchEvents = async (searchQuery) => {
  try {
    const eventsRef = collection(db, "events");

    // Create a query based on the search term
    // This is a basic example - you might need more complex queries
    const q = query(
      eventsRef,
      where("name", ">=", searchQuery),
      where("name", "<=", searchQuery + "\uf8ff")
    );

    const querySnapshot = await getDocs(q);
    const events = [];

    querySnapshot.forEach((doc) => {
      events.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    return events;
  } catch (error) {
    console.error("Error searching events:", error);
    throw error;
  }
};
