import React from "react";
import styled from "styled-components";
import ErrorForm from "../../../libs/reuse/components/ErrorForm";
import { firestoreErrors } from "../../../errors/firestoreErrors";
import { useEvents } from "../hooks/useEvents";
import { useTranslation } from "react-i18next";
import EventForm from "../../event-form/EventForm";

function AddEvent() {
  const {
    startDate,
    endDate,
    name,
    location,
    description,
    link,
    type,
    isPinned,
    errors,
    isKidRace,
    isParaRace,
    isRelayRace,
    addNewEvent,
  } = useEvents();
  const { t } = useTranslation("middleModale");

  const form = {
    startDate,
    endDate,
    name,
    location,
    description,
    link,
    type,
    isPinned,
    errors,
    isKidRace,
    isParaRace,
    isRelayRace,
  }

  return (
    <Wrapper>
      <EventForm
        form={form}
        onSubmit={addNewEvent}
        isNewEvent={true} // Flag to indicate this is a new event 
      />
      {errors.firestore && (
        <ErrorForm
          error={
            firestoreErrors[errors.firestore] ||
            t("somethingWentWrong")
          }
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
`;

export default AddEvent;
