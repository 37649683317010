import React, { useCallback, useEffect, useState } from 'react'
import Banner from './Banner'
import styled from 'styled-components'
import { Heading4 } from '../../../libs/reuse/components/texts/textStyles'
import Button from '../../../libs/reuse/components/Button'
import Input from '../../../libs/reuse/components/Input'
import { getBannerById, updateBannerById } from '../api/firebase'
import { useNotification } from '../../../providers/NotificationProvider'
import { useTranslation } from 'react-i18next'
import useDebouncedValidation from '../../../libs/reuse/hooks/useDebounce'
import validator from "validator";

function BannerSettings({ bannerId }) {
    const { t } = useTranslation("banners");
    const { setNotification } = useNotification();

    const validateLink = useCallback((link) => {
        return (validator.isEmpty(link) ||
            !validator.isURL(link, {
                protocols: ["http", "https"],
                require_tld: true,
                require_protocol: true,
            }))
            && t("emptyLink")
    }, [t])
    const link = useDebouncedValidation("", validateLink)
    const url = useDebouncedValidation("", validateLink)
    const [currentUrl, setCurrentUrl] = useState();
    const [currentLink, setCurrentLink] = useState();

    useEffect(() => {
        getBannerById(bannerId)
            .then(res => {
                const banner = res.data();
                console.log(banner);
                link.setInput(banner.link)
                url.setInput(banner.url)
                setCurrentUrl(banner.url);
                setCurrentLink(banner.link);
            })
            .catch(err => console.log(err))
    }, [bannerId])

    const saveHandler = () => {
        updateBannerById(bannerId, { url: url.input, link: link.input }).then(() => {
            setNotification({
                success: t("bannerUpdated")
            })
            setCurrentUrl(url.input);
            setCurrentLink(link.input);
        })
    }

    const deleteHandler = () => {
        updateBannerById(bannerId, { url: "", link: "" }).then(() => {
            setNotification({
                success: t("bannerDeleted")
            })
            url.input = ""
            link.input = ""
            setCurrentUrl("");
            setCurrentLink("");
        })
    }

    return (
        <Form>
            <Content>
                <Section>
                    <Input
                        text={t("bannerImageURL")}
                        icon="link"
                        type="URL"
                        {...url}
                    />
                    <Input
                        text={t("link")}
                        icon="link"
                        type="URL"
                        placeholder={t("enterLink")}
                        {...link}
                    />
                </Section>
                <Actions>
                    <Button
                        iconName="save"
                        color="#6689A1"
                        onClick={() => saveHandler()}
                    />
                    <Button
                        iconName="delete"
                        color="#ED6A5A"
                        onClick={() => deleteHandler()}
                    />
                </Actions>
            </Content>
            <Preview>
                <Heading4>{t("preview")}</Heading4>
                {currentUrl && <Banner banner={{ url: currentUrl, link: currentLink }} />}
            </Preview>
        </Form>
    )
}

const Form = styled.div`
display: flex;
flex-direction: column;
gap: 25px;
`

const Content = styled.div`
display: flex;

`

const Section = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
flex-grow: 1;
gap: 5px;

`

const Actions = styled.div`
display:flex;
flex-direction: column;
align-self: center;
gap: 5px;
`

const Preview = styled.div`
    width: 100%;
`

export default BannerSettings