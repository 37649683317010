import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { useLocale } from '../../providers/LocaleProvider';
import { useAuth } from '../../providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import Input from '../../libs/reuse/components/Input';
import ErrorForm from '../../libs/reuse/components/ErrorForm';
import ButtonContained from '../../libs/reuse/components/ButtonContained';
import ButtonOutlined from '../../libs/reuse/components/ButtonOutlined';
import { useNavigate } from 'react-router';
import { Heading2 } from '../../libs/reuse/components/texts/textStyles';

function ForgotPassword() {
    const navigate = useNavigate()
    const { lang } = useLocale();
    const { resetUserPassword, error, resetError } = useAuth();
    const { t } = useTranslation("auth")

    const [email, setEmail] = useState("");

    useEffect(() => {
        resetError();
    }, []);

    const resetHandler = () => {
        resetUserPassword(email, lang);
    };

    return (
        <Wrapper onClick={(e) => e.stopPropagation()}>
            <Header>
                <Heading2>{t("resetPassword")}</Heading2>
            </Header>
            <Content>
                <Input
                    text={t("email")}
                    type="email"
                    value={email}
                    onChange={setEmail}
                />
            </Content>
            {error && (
                <ErrorForm
                    error={
                        t(error, { ns: "errors" }) || t("somethingWentWrong")
                    }
                />
            )}
            <ButtonContained
                text={t("reset")}
                color="#6689A1"
                onClick={(e) => {
                    e.preventDefault();
                    resetHandler();
                }}
            />
            <Line />
            <Text>{t("or")}</Text>
            <ButtonOutlined
                text={t("signIn")}
                color="#6689A1"
                onClick={() => navigate("/login")}
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    padding: 30px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    box-sizing: border-box;
    color: #303134;
  `;

const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #303134;
  `;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 500px;

    @media (max-width: 760px) {
        width: 100%;
    }
  `;

const Text = styled.div`
    font-family: "Cuprum";
  `;

const Line = styled.div`
    width: 50%;
    height: 1px;
    background-color: #d7d7d7;
  `;

export default ForgotPassword;
