import React from "react";
import styled from "styled-components";
import { Body2, Heading4 } from "../../../libs/reuse/components/texts/textStyles";
import { useTranslation } from "react-i18next";

function OwnerCard({ owner, showAddedBy = false, showUpdatedBy = false }) {
  const { t } = useTranslation("admin")
  if (!owner) {
    return;
  }
  const { displayName, email, photoURL, role } = owner;
  return (
    <Wrapper>
      <Heading4>{showAddedBy && t("addedBy")}{showUpdatedBy && t("updatedBy")}</Heading4>
      <Content>
        <Picture url={photoURL}>
          {photoURL && <img alt="user" srcSet={photoURL} />}
        </Picture>
        <PersonalData>
          {displayName && <Heading4>{displayName}</Heading4>}
          {email && <Body2>{email}</Body2>}
          {role && <Body2>{role}</Body2>}
        </PersonalData>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 10px;
  min-width: 240px;
  color: #303134;

  @media (max-width: 1600px) {
    min-width: 180px;
  }

  @media (max-width: 780px) {
    width: 100%;
    align-items: start;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 780px) {
    flex-direction: row;
    align-self: center;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-self: flex-start;
  }
`;

const PersonalData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
`;

const Picture = styled.div`
  border-radius: 16px;
  width: 150px;
  height: 150px;
  background-color: #c4c4c4;
  ${(props) => props.url && "outline: 2px solid #6689A1;"}

  img {
    border-radius: 16px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 780px) {
    width: 120px;
    height: 120px;
  }
`;
export default OwnerCard;
