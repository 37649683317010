import React from "react";
import { ReactSVG } from "react-svg";
import styled, { css } from "styled-components";

const StyledSVGIcon = styled(ReactSVG)`
  svg {
    fill: inherit;
    ${({ size }) =>
      size
        ? `
        width: ${size};
        height: ${size};
      `
        : `
        width: 24px;
        height: 24px;
      `}
    ${({ transform }) =>
      transform &&
      css`
        transform: ${transform};
      `}
    ${({ padding }) =>
      padding &&
      css`
        padding: ${padding};
      `}
    path {
      ${({ color }) =>
        color &&
        css`
          fill: ${color};
        `}
    }
  }

  div {
    display: flex;
  }
`;

const Icon = ({ name, color, size, transform, padding }) => {
  return (
    <StyledSVGIcon
      src={`/icons/${name}.svg`}
      color={color}
      size={size}
      transform={transform}
      padding={padding}
    />
  );
};

export default Icon;
