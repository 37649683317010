import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getBannerById } from "./api/firebase";
import { ID } from "./components/AssignBanners";

function LeftSideBanner() {
  const [banner, setBanner] = useState();

  useEffect(() => {
    getBannerById(ID.SIDE1)
      .then(res => {
        setBanner(res.data())
      })
      .catch(err => console.log(err))
  }, [])

  if (!banner) { return }

  return <Wrapper >{banner.url && <Link href={banner.link} target="_blank" rel="nofollow" ><img src={banner.url} srcSet={banner.url} alt="banner" /></Link>}</Wrapper>;
}
const Wrapper = styled.div`  
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 320px;
`

const Link = styled.a`
height: 100%;
max-width: 100%;

img {
  max-height: 100%;
  max-width: 100%;
}
`

export default LeftSideBanner;
