import React from "react";
import CalendarPage from "../Calendar/Calendar";

function Home() {
  return (
    <CalendarPage />
  );
}

export default Home;
