import React, { useState } from "react";
import styled from "styled-components";
import { useModals } from "../../../providers/ModalsProvider";
import ButtonContained from "../../../libs/reuse/components/ButtonContained";
import { Heading6 } from "../../../libs/reuse/components/texts/textStyles";
import { useTranslation } from "react-i18next";
import CircleLoader from "../../../libs/reuse/components/CircleLoader";

function RemoveFromMyCalendar() {
    const { calendarModal, closeCalendarModal } = useModals();

    const [isRemoving, setIsRemoving] = useState(false);

    // Extract data from the calendarModal
    const callback = calendarModal?.data?.callback;
    const { t } = useTranslation("middleModale");

    return (
        <Wrapper>
            <Content>
                <Heading6>{t("removeEventFromMyCalendar")}</Heading6>
                <Heading6>{t("areYouSure")}</Heading6>
                <ButtonContained
                    text={t("no")}
                    color="#6689A1"
                    onClick={() => closeCalendarModal()}
                />
                <ButtonContained
                    text={isRemoving ?
                        <CircleLoader position="relative" top="50%" left="50%" translateX="-50%" translateY="0" color="#ffffff" /> : t("yes")}
                    color="#ED6A5A"
                    onClick={() => {
                        setIsRemoving(true);
                        callback();
                    }}
                />
            </Content>
        </Wrapper>
    );
}

export default RemoveFromMyCalendar;

const Wrapper = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;


