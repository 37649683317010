import "firebase/firestore";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../firebase-config";

export const addUser = async (id, user) => {
  try {
    await setDoc(doc(db, "users", id), user);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const getAllUsers = async () => {
  return await getDocs(collection(db, "users"));
};

export const getUserById = async (id) => {
  const docRef = doc(db, "users", id);
  return await getDoc(docRef);
};

export const updateUser = async (id, user) => {
  try {
    await updateDoc(doc(db, "users", id), user);
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};

export const deleteUserById = async (id) => {
  try {
    await deleteDoc(doc(db, "users", id));
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};
