import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Heading2 } from "../../libs/reuse/components/texts/textStyles";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import Loader from "../../libs/reuse/components/Loader";
import EventCard from "../../features/event/EventCard";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase-config";

function SingleEvent() {
    const { eventId } = useParams();
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { t } = useTranslation('events');

    useEffect(() => {
        if (!eventId) {
            setError('No event ID provided');
            setLoading(false);
            return;
        }

        setLoading(true);

        // Subscribe to real-time updates for the event
        const unsubscribe = onSnapshot(
            doc(db, "events", eventId), // Replace "events" with your Firestore collection name
            (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    setEvent({
                        id: eventId,
                        ...data,
                        startDate: data.startDate.toDate(),
                        endDate: data.endDate.toDate(),
                    });
                    setLoading(false);
                } else {
                    setError('Event not found');
                    setLoading(false);
                }
            },
            (err) => {
                console.error('Error fetching event:', err);
                setError(t('errorLoadingEvent'));
                setLoading(false);
            }
        );

        // Cleanup the listener on component unmount
        return () => unsubscribe();
    }, [eventId, t]);

    if (loading) {
        return (
            <Loader />
        );
    }

    if (error) {
        return (
            <ErrorWrapper>
                <Heading2>{t('eventNotFound')}</Heading2>
                <p>{error}</p>
            </ErrorWrapper>
        );
    }

    return (
        <>
            {/* SEO helmet - placed at the top level of the component return */}
            {event && <Helmet>
                <title>{event.name} | iRace</title>
                <meta name="description" content={`${event.name} - ${event.location.name} - ${event.description?.substring(0, 160) || ''}...`} />
                <meta property="og:title" content={`${event.name} | iRace`} />
                <meta property="og:description" content={`${event.name} - ${event.location.name}`} />
                <meta property="og:image" content={process.env.REACT_APP_OG_IMAGE} />
            </Helmet>
            }
            <Wrapper>
                <Header>
                    <Heading2>{t('eventDetails')}</Heading2>
                </Header>
                <EventCardWrapper>
                    <EventCard event={event} />
                </EventCardWrapper>
            </Wrapper>
        </>
    );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const EventCardWrapper = styled.div`
  width: 100%;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-align: center;
  height: 400px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default SingleEvent;