import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase-config";

export const addUpdate = async (request) => {
  await addDoc(collection(db, "updateRequests"), request);
};

export const getAllUpdateRequests = async () => {
  return await getDocs(collection(db, "updateRequests"));
};

export const getAllPendingUpdateRequestsQuery = () => {
  const q = query(
    collection(db, "updateRequests"),
    where("status", "==", "PENDING")
  );

  return q;
};

export const getAllPendingUpdateRequests = async () => {
  return await getDocs(getAllPendingUpdateRequestsQuery());
};

export const updateUpdateById = async (id, update) => {
  try {
    await updateDoc(doc(db, "updateRequests", id), update);
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};
