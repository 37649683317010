import React from 'react'
import styled from 'styled-components';
import ToggleButton from '../../../libs/reuse/components/ToggleButton';
import { useAuth } from '../../../providers/AuthProvider';
import { ADMIN } from '../../../constants/roles';

function EventPerks({ isKidRace, setIsKidRace, isParaRace, setIsParaRace, isRelayRace, setIsRelayRace, isPinned, setIsPinned }) {
    const { user } = useAuth();
    return (
        <Perks>
            <ToggleButton
                isSelected={isKidRace}
                iconName="kids"
                iconSize="35px"
                color="#717579"
                onClick={() => setIsKidRace((prev) => !prev)}
            />
            <ToggleButton
                isSelected={isParaRace}
                iconName="para"
                iconSize="35px"
                color="#717579"
                onClick={() => setIsParaRace((prev) => !prev)}
            />
            <ToggleButton
                isSelected={isRelayRace}
                iconName="relay"
                iconSize="35px"
                color="#717579"
                onClick={() => setIsRelayRace((prev) => !prev)}
            />
            {user.role === ADMIN && (
                <ToggleButton
                    isSelected={isPinned}
                    iconName="pin"
                    iconSize="35px"
                    color="#717579"
                    onClick={() => setIsPinned((prev) => !prev)}
                />
            )}
        </Perks>
    )
}

const Perks = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
`;

export default EventPerks