import React from "react";
import styled from "styled-components";
import { useModals } from "../../providers/ModalsProvider";
import {
  HAMBURGER_MODAL,
  SETTINGS_MODAL,
} from "../../constants/modals";
import Settings from "./components/Settings";
import HamburgerMenu from "./components/HamburgerMenu";

function HeaderModale() {
  const { headerModal, closeHeaderModal } = useModals();
  return (
    <WhiteOut
      onClick={(e) => {
        closeHeaderModal();
      }}
    >
      {headerModal === SETTINGS_MODAL && <Settings />}
      {headerModal === HAMBURGER_MODAL && <HamburgerMenu />}
    </WhiteOut>
  );
}

const WhiteOut = styled.div`
  position: absolute;
  top: 100%;
  left: 0px;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
`;

export default HeaderModale;
