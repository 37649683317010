import React from "react";
import styled from "styled-components";

import Button from "./Button";
import { Body2 } from "./texts/textStyles";

function SearchInput({
  defaultText,
  value,
  onChange,
  onClick,
  onKeyDown,
  ref,
}) {
  return (
    <Wrapper onKeyDown={onKeyDown}>
      <InputBox
        ref={ref}
        type="text"
        placeholder={defaultText}
        value={value}
        onChange={onChange}
      />
      <Button
        iconName="search"
        iconSize="32px "
        color="#717579"
        onClick={onClick}
      />
    </Wrapper>
  );
}

const Wrapper = styled(Body2)`
  display: flex;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #eeeeee;
  background-color: #FBFBFB;
`;

const InputBox = styled.input`
  border: none;
  background-color: transparent;
  padding: 20px 20px;
  font: inherit;

  /* Secondary Text */
  color: #717579;

  &:focus {
    outline: none;
  }
`;

export default SearchInput;
