import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import Button from "./Button";
import { useNotification } from "../../../providers/NotificationProvider";
import { Heading3, Heading7 } from "./texts/textStyles";
import { useTranslation } from "react-i18next";

function Notification() {
  const { isVisible = false, notification = null, resetNotification } = useNotification();
  const { t } = useTranslation("contactUs");

  // Return null if there's no notification to show
  if (!notification && !isVisible) return null;

  return (
    <Wrapper className={isVisible ? "appear" : notification && "disappear"}>
      <Status error={notification?.error}>
        <Icon
          name={(notification?.error && "denied") || "tick"}
          color="#ffffff"
        />
      </Status>
      <Message>
        <Title>{(notification?.error && t("error")) || t("success")}</Title>
        <Description>
          {notification?.error || notification?.success}
        </Description>
      </Message>
      <Button
        iconName="close"
        color="#ED6A5A"
        iconSize="18px"
        onClick={() => resetNotification()}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  border-radius: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  position: fixed;
  right: -100%;
  top: 120px;
  max-width: 400px;
  opacity: 0;
  z-index: 1000;
  transition: right 0.5s, opacity 0.5s;

  &.appear {
    animation: smooth-appear 1s ease forwards;
  }

  @keyframes smooth-appear {
    from {
      right: -100%;
      opacity: 0;
    }
    to {
      right: 30px;
      opacity: 1;
    }
  }

  &.disappear {
    animation: smooth-disappear 1s ease forwards;
  }

  @keyframes smooth-disappear {
    from {
      right: 30px;
      opacity: 1;
    }
    to {
      right: -100%;
      opacity: 0;
    }
  }

  @media (max-width: 480px) {
    top: 5px;
    width: 98%;

    @keyframes smooth-appear {
    from {
      right: -100%;
      opacity: 0;
    }
    to {
      right: 5px;
      opacity: 1;
    }
  }

  @keyframes smooth-disappear {
    from {
      right: 5px;
      opacity: 1;
    }
    to {
      right: -100%;
      opacity: 0;
    }
  }
  }

`;

const Status = styled.div`
  border-radius: 15px 0px 0px 15px;
  background-color: ${({ error }) => (error ? "#ED6A5A" : "#BBD900")};
  padding: 15px 20px;
  display: flex;
  align-items: center;
`;

const Message = styled.div`
  padding: 12px 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  flex-grow: 1;
`;

const Title = styled(Heading3)`
`;

const Description = styled(Heading7)`
`;

export default Notification;
