import React from 'react'
import styled from 'styled-components';
import { useAuth } from '../../../providers/AuthProvider';
import Button from '../../../libs/reuse/components/Button';
import Profile from './Profile';
import { useModals } from '../../../providers/ModalsProvider';
import { HAMBURGER_MODAL } from '../../../constants/modals';
import logo from "../../../assets/logos/iRaceLogo3.webp";
import Language from './Language';

function MobileHeader() {
  const { user } = useAuth();
  const { openHeaderModal, closeHeaderModal, headerModal } = useModals();

  return (
    <Mobile>
      <Image onClick={() => window.location.replace("/")}>
        <img src={logo} alt="iRace logo" />
      </Image>
      <Section>
        {user && <Profile />}
        <Language />
        {headerModal ? (
          <Button
            iconName="close"
            color="#ED6A5A"
            onClick={() => closeHeaderModal()}
          />
        ) : (
          <Button
            iconName="hamburger"
            iconSize="24px"
            color="#717579"
            onClick={() => {
              openHeaderModal(HAMBURGER_MODAL);
            }}
          />
        )}
      </Section>
    </Mobile>
  )
}

const Mobile = styled.div`
  display: none;
  justify-content: space-between; 
  align-items: center;
  z-index: 2;

  button {
    padding: 12px 12px;
  }

  @media (max-width: 960px) {
    display: flex;
    flex-grow: 1;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
`;

const Image = styled.div`
  padding: 31px 0px 0px 0px;
  max-width: 175px;
  cursor: pointer;

  @media (max-width: 480px) {
    padding: 10px 0px 0px 0px;
    max-width: 130px;
  }

  img {
    object-fit: contain;
    min-width: 130px;
  }
`;

const Section = styled.div`
    display: flex;
    gap: 10px;
    `

export default MobileHeader