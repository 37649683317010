import React from "react";
import styled, { css } from "styled-components";
import Icon from "./Icon";
import { Heading4 } from "./texts/textStyles";

function ButtonContained({ text, iconName, iconSize, color, onClick, disabled, type = "" }) {
  return (
    <Wrapper color={color} onClick={onClick} disabled={disabled} type={type}>
      {iconName ? <Icon name={iconName} size={iconSize} /> : <></>}
      <Heading4>{text}</Heading4>
    </Wrapper>
  );
}

const Wrapper = styled.button`
  font: inherit;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
  border-radius: 16px;
  padding: 13px 25px;
  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}

  &:hover {
    filter: saturate(0.8);
  }

  &:disabled {
    filter: saturate(0.3);
    cursor: not-allowed;
  }

  /* White */
  color: #ffffff;
  fill: #ffffff;
`;

export default ButtonContained;
