import React, { Suspense } from 'react';
import Loader from './Loader';

const LazyLoad = ({ component: Component, ...props }) => {
    return (
        <Suspense fallback={<Loader />}>
            <Component {...props} />
        </Suspense>
    );
};

export default LazyLoad;