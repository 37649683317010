import React, { useEffect, useRef, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import styled from "styled-components";
import { Heading5 } from "../../../libs/reuse/components/texts/textStyles";
import { useTranslation } from "react-i18next";
import Input from "../../../libs/reuse/components/Input";

const options = {
    types: ["geocode", "establishment"],
    componentRestrictions: { country: ["bg", "gr", "ro", "rs", "tr", "mk"] },
    language: "en",
};

function EventLocation({ input, onChange, setError, validateLocation }) {
    const { t } = useTranslation("events");
    const [suggestions, setSuggestions] = useState([]);
    const [query, setQuery] = useState(input.name || "");
    const suggestionClickedRef = useRef(false);

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
    } = usePlacesService({
        apiKey: process.env.REACT_APP_google,
        debounce: "1000",
        options,
    });

    // Add this effect to update query when input changes
    useEffect(() => {
        if (input && input.name) {
            setQuery(input.name);
        }
    }, [input]);

    // Update suggestions whenever predictions change
    useEffect(() => {
        setSuggestions(placePredictions?.length ? placePredictions : null);
    }, [placePredictions]);

    const handleSelectedSuggestion = ({ place_id, description }) => {
        setSuggestions([]);
        placesService?.getDetails({ placeId: place_id }, (placeDetails) => {
            if (placeDetails?.geometry?.location) {
                const locationData = {
                    name: description,
                    lat: placeDetails.geometry.location.lat(),
                    lng: placeDetails.geometry.location.lng(),
                };
                setQuery(description);
                onChange(locationData);
            }
        });
    };

    const handleBlur = () => {
        if (suggestionClickedRef.current) {
            suggestionClickedRef.current = false;
            return;
        }
        setQuery(input.name)
        setSuggestions([]);
        setError(validateLocation(input))
    };

    const handleMouseDown = () => {
        suggestionClickedRef.current = true;
    };

    return (
        <Wrapper>
            <Input
                text={t("location")}
                placeholder={t("enterLocation")}
                input={query}
                onChange={(value) => {
                    setError("");
                    setQuery(value);
                    getPlacePredictions({ input: value });
                }}
                onBlur={handleBlur}
            />
            {suggestions && suggestions.length > 0 && (
                <List>
                    {suggestions.map(({ place_id, description }) => (
                        <City
                            key={place_id}
                            onMouseDown={handleMouseDown}
                            onClick={() => handleSelectedSuggestion({ place_id, description })}
                        >
                            <Heading5>{description}</Heading5>
                        </City>
                    ))}
                </List>
            )}
        </Wrapper>
    );
}

// Styled Components
const Wrapper = styled.div`
  position: relative;
`;

const List = styled.div`
  position: absolute;
  width: 100%;
  background: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 14px;
  color: #303134;
  fill: #717579;
  
  div:first-child {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }
  
  div:last-child {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }
`;

const City = styled.div`
  padding: 7px 25px;
  cursor: pointer;
  &:hover {
    background-color: #E6ECEF;
  }
`;

export default EventLocation;
