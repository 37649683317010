import React, { useState } from "react";
import styled from "styled-components";
import ErrorForm from "./ErrorForm";
import Icon from "./Icon";
import { Heading5, Heading6 } from "./texts/textStyles";

function Input({
  text,
  type = "text",
  icon,
  placeholder,
  error,
  input,
  onChange,
  disabled,
  autoComplete,
  showEye = false,
  onBlur,
  onFocus,
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Wrapper>
      <Title>{text}</Title>
      <Box disabled={disabled}>
        {icon && <Icon name={icon} size="24px" />}
        <InputField
          type={showPassword ? "text" : type}
          placeholder={placeholder}
          value={input}
          onChange={(e) => onChange(e.target.value)}
          autoComplete={autoComplete}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        {showEye && <EyeButton onClick={() => setShowPassword(!showPassword)} ><Icon name={showPassword ? "eye-off" : "eye"} size="24px" /></EyeButton>}
      </Box>
      {error && <ErrorForm error={error} />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  flex-grow: 1;
`;

const Title = styled(Heading6)`
  text-transform: uppercase;
  /* Secondary Text */
  color: #717579;
`;

const Box = styled(Heading5)`
  border: 1px solid #d7d7d7;
  border-radius: 14px;
  padding: 7px 22px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  color: #303134;
  fill: #717579;
  align-items: center;
  background-color: ${({ disabled }) => (disabled ? "#EEEEEE" : "none")};
  &:focus {
    border: 1px solid black;
  }
`;

const InputField = styled.input`
  -webkit-background-clip: text;
  width: 100%;
  border: none;
  font: inherit;

  /* Primary Text */
  color: #303134;

  &:focus {
    outline: none;
  }
`;

const EyeButton = styled.div`
cursor: pointer;
`

export default Input;
