import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useModals } from "./ModalsProvider";
import { onSnapshot, Timestamp } from "firebase/firestore";
import {
  getAllEventsPerMonth,
  getAllEventsPerMonthQuery,
  getAllEventsQuery,
  getAllEventsSnapshot,
} from "../features/events/api/firebase";
import { ADD_EVENT_MODAL } from "../constants/modals";
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  format,
  isWithinInterval,
  startOfMonth,
  startOfWeek,
  subMonths,
} from "date-fns";
import { useLocale } from "./LocaleProvider";
import { EVENT_STATUS } from "../constants/events";
import { useAuth } from "./AuthProvider";
import { useNavigate } from "react-router";

const CalendarContext = createContext();

const CalendarProvider = ({ children }) => {
  const { user } = useAuth();
  const { openCalendarModal } = useModals();
  const { lang } = useLocale();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const [today] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const [events, setEvents] = useState([]);
  const [filterType, setFilterType] = useState("");
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [weekArray, setWeekArr] = useState();
  const [weeks, setWeeks] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);
    const unsub = onSnapshot(getAllEventsQuery(), (res) => {
      let eventsArr = [];
      res.docs.forEach((doc) => {
        const { startDate, endDate } = doc.data();
        eventsArr.push({
          id: doc.id,
          ...doc.data(),
          startDate: startDate.toDate(),
          endDate: endDate.toDate(),
        });
      });
      setEvents(eventsArr);
      setLoading(false);
    });

    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    let firstDayOfWeek = startOfWeek(today, { weekStartsOn: 1 });
    let weekDays = [];

    for (let i = 0; i <= 6; i++) {
      weekDays.push(addDays(firstDayOfWeek, i));
    }

    setWeekArr(weekDays);
  }, [lang, today]);

  useEffect(() => {
    setWeeks([]);
    const monthStart = startOfMonth(date);
    const monthEnd = endOfMonth(date);
    let startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
    const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });

    while (startDate <= endDate) {
      const week = [];
      for (let i = 1; i < 8; i++) {
        week.push(startDate);
        startDate = addDays(startDate, 1);
      }
      setWeeks((weeks) => [...weeks, week]);
    }
  }, [date]);

  const errorHandler = (err) => {
    console.log(err);
  };

  const resetError = () => {
    setError("");
  };

  const applyFilter = useCallback(
    (events) => {
      // First, filter by event status
      const eventsArr = events.filter(
        (event) =>
          event.status === EVENT_STATUS.APPROVED ||
          event.status === EVENT_STATUS.CANCELLED
      );

      // If no type is active, return all events after status filtering
      if (!filterType) {
        return eventsArr;
      }

      // Otherwise, filter events to include only those with a type in activeTypes
      return eventsArr.filter((event) => event.type[filterType]);
    },
    [filterType]
  );

  useEffect(() => {
    const monthStart = startOfMonth(date);
    const monthEnd = endOfMonth(date);
    const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
    const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });

    getAllEventsPerMonth(
      Timestamp.fromDate(startDate),
      Timestamp.fromDate(endDate)
    )
      .then((res) => {
        let eventsArr = [];
        res.docs.forEach((doc) => {
          const { startDate, endDate } = doc.data();
          eventsArr.push({
            id: doc.id,
            ...doc.data(),
            startDate: startDate.toDate(),
            endDate: endDate.toDate(),
          });
        });
        setFilteredEvents(applyFilter(eventsArr));
      })
      .catch((err) => errorHandler(err));
  }, [applyFilter, date, events]);

  const prevMonth = () => {
    setDate((prev) => subMonths(prev, 1));
  };

  const nextMonth = () => {
    setDate((prev) => addMonths(prev, 1));
  };

  const setToday = () => {
    setDate(new Date());
  };

  const addEvent = () => {
    user ? openCalendarModal(ADD_EVENT_MODAL) : navigate("/add-event");
  };

  const getTodayEvents = (day, events) => {
    return events.filter((event) => {
      return isWithinInterval(format(day, "yyyy-MM-dd"), {
        start: format(event.startDate, "yyyy-MM-dd"),
        end: format(event.endDate, "yyyy-MM-dd"),
      });
    });
  };

  const dayIsReserved = (day) => {
    const todaysEvents = getTodayEvents(day, events);
    let isReserved = false;
    for (const event of todaysEvents) {
      if (user?.events?.[event.id]) {
        isReserved = true;
        break;
      }
    }
    return isReserved;
  };

  return (
    <CalendarContext.Provider
      value={{
        date,
        today,
        weekArray,
        weeks,
        events,
        filterType,
        setFilterType,
        filteredEvents,
        error,
        loading,
        resetError,
        prevMonth,
        nextMonth,
        setToday,
        addEvent,
        getTodayEvents,
        dayIsReserved,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

export default CalendarProvider;

export const useCalendar = () => {
  return useContext(CalendarContext);
};
