import React from "react";
import styled, { css } from "styled-components";
import Icon from "./Icon";
import { Heading4 } from "./texts/textStyles";

function ToggleButton({
  type = "button",
  iconName,
  iconSize,
  text,
  color,
  onClick,
  isSelected,
}) {
  return (
    <Wrapper
      type={type}
      color={color}
      onClick={onClick}
      className={isSelected ? "selected" : ""}
    >
      {iconName ? <Icon name={iconName} size={iconSize} /> : <></>}
      {text && <Heading4>{text}</Heading4>}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  font: inherit;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  gap: 27px;
  border: none;
  border-radius: 14px;
  padding: 12px 25px;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
      fill: ${color};
      border: 1px solid ${color};
      opacity: 0.8;
    `}

  &:hover {
    opacity: 1;
  }

  &.selected {
    color: #6689A1;
    fill: #6689A1;
    border-color: #6689A1;
  }
`;

export default ToggleButton;
