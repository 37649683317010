import { useContext, createContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "./AuthProvider";
import { enGB, bg } from "date-fns/locale";
import { EN } from "../constants/lang";

const dateLocales = { bg: bg, en: enGB };

const LocaleContext = createContext();

const LocaleProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const { user } = useAuth();

  const [lang, setLang] = useState(i18n.language);
  const [timeLang, setTimeLang] = useState(dateLocales.en);

  const updateLang = (lang) => {
    setLang(lang);
  };

  useEffect(() => {
    if (user) {
      setLang(user.lang || EN);
    }
  }, [user]);

  useEffect(() => {
    i18n.changeLanguage(lang);
    setTimeLang(dateLocales[lang]);
  }, [i18n, lang]);

  return (
    <LocaleContext.Provider value={{ lang, timeLang, updateLang }}>
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;

export const useLocale = () => {
  return useContext(LocaleContext);
};
