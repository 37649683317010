// Top-level error fallback for the entire app
const AppErrorFallback = ({ error, resetErrorBoundary }) => {
    return (
        <div style={{
            padding: '2rem',
            margin: '2rem auto',
            maxWidth: '600px',
            textAlign: 'center',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
        }}>
            <h1 style={{ color: '#e53935' }}>Something went wrong</h1>
            <p>We're sorry, but something went wrong with the application.</p>

            {process.env.NODE_ENV === 'development' && (
                <pre style={{
                    margin: '1rem 0',
                    padding: '1rem',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '4px',
                    overflow: 'auto',
                    textAlign: 'left'
                }}>
                    {error.message}
                </pre>
            )}

            <button
                onClick={resetErrorBoundary}
                style={{
                    backgroundColor: '#6689A1',
                    color: 'white',
                    border: 'none',
                    borderRadius: '16px',
                    padding: '13px 25px',
                    cursor: 'pointer',
                    fontSize: '1rem',
                    marginTop: '1rem'
                }}
            >
                Reload Application
            </button>
        </div>
    );
};

export default AppErrorFallback;