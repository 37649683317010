import React from "react";
import ReactSwitch from "react-switch";
import styled from "styled-components";
import { Heading7 } from "./texts/textStyles";

function Switch({ text, value, onChange, height = 24, width = 48, handleDiameter = 22, disabled }) {
  return (
    <Wrapper>
      {text && <Title>{text}</Title>}
      <Box>
        <ReactSwitch
          onChange={onChange}
          checked={value}
          onColor="#6689A1"
          offColor="#717579"
          activeBoxShadow="0 0 2px 3px #6689A1"
          height={height}
          width={width}
          handleDiameter={handleDiameter}
          disabled={disabled}
        />
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  align-self: baseline;
`;

const Title = styled(Heading7)`
  text-transform: uppercase;

  /* Secondary Text */
  color: #717579;
`;

const Box = styled.div`
  border: 1px solid transparent;
  border-radius: 14px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  color: #303134;
  fill: #717579;
  align-items: center;
  background-color: ${({ disabled }) => (disabled ? "#EEEEEE" : "none")};
  &:focus {
    border: 1px solid black;
  }
`;

export default Switch;
