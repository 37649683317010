import React from "react";
import styled from "styled-components";
import { Body2 } from "./texts/textStyles";

function ErrorForm({ error }) {
  return <Wrapper><Body2>{error}</Body2></Wrapper>;
}

const Wrapper = styled.div`
  color: #ED6A5A;
`;

export default ErrorForm;
