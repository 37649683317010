import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router";
import ModalsProvider from "./providers/ModalsProvider";
import "./i18n/i18n";
import NotificationProvider from "./providers/NotificationProvider";
import AuthProvider from "./providers/AuthProvider";
import LocaleProvider from "./providers/LocaleProvider";
import CalendarProvider from "./providers/CalendarProvider";
import "./sentry";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ModalsProvider>
        <NotificationProvider>
          <AuthProvider>
            <LocaleProvider>
              <CalendarProvider>
                <App />
              </CalendarProvider>
            </LocaleProvider>
          </AuthProvider>
        </NotificationProvider>
      </ModalsProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
