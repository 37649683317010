
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Icon from "../../libs/reuse/components/Icon";
import Button from "../../libs/reuse/components/Button";
import { useModals } from "../../providers/ModalsProvider";
import { EDIT_EVENT_MODAL, REMOVE_FROM_CALENDAR_MODAL, UPDATE_EVENT_MODAL } from "../../constants/modals";
import { useAuth } from "../../providers/AuthProvider";
import { format } from "date-fns";
import { useLocale } from "../../providers/LocaleProvider";
import Switch from "../../libs/reuse/components/Switch";
import { ADMIN } from "../../constants/roles";
import { EVENT_STATUS } from "../../constants/events";
import { updateUser } from "../users/api/firebase";
import { deleteEventById, updateEventById } from "../events/api/firebase";
import { useNotification } from "../../providers/NotificationProvider";
import OwnerCard from "../../page/Admin/components/OwnerCard";
import { useLocation, useNavigate } from "react-router";
import { Body1, Body2, Heading3 } from "../../libs/reuse/components/texts/textStyles";
import { useTranslation } from "react-i18next";
import Requirements from "../event-form/components/Requirements";
import CircleLoader from "../../libs/reuse/components/CircleLoader";
import Copy from "./components/Copy";

function EventCard({ event, addedBy = undefined, updatedBy = undefined, isUpdate = false }) {
  const {
    id,
    startDate,
    endDate,
    name,
    location,
    description,
    link,
    type,
    isKidRace,
    isParaRace,
    isRelayRace,
    requirements,
  } = event;
  const { openCalendarModal, closeCalendarModal } = useModals();
  const { user, id: uid, updateUserDetails } = useAuth();
  const { timeLang } = useLocale();
  const { setNotification } = useNotification();
  const locationURL = useLocation()
  const { t } = useTranslation("events");
  const navigate = useNavigate();

  const isAdminPage = locationURL?.pathname?.includes("admin")
  const [isAdded, setisAdded] = useState(user?.events?.[id] || false);
  const isCancelled = event.status === EVENT_STATUS.CANCELLED;
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [fullfilled] = useState(user?.events?.id);
  const [loadingStates, setLoadingStates] = useState({});

  const addToMyCalendar = () => {
    if (user) {
      setLoadingStates(prev => ({ ...prev, add: true }));
      const events = user.events;
      events[id] = {};
      updateUser(uid, { ...user, events })
        .then(() => {
          updateUserDetails(() => { setLoadingStates(prev => ({ ...prev, add: false })) });
        })
        .catch((err) => {
          console.log(err)
          setLoadingStates(prev => ({ ...prev, add: false }))
        });
    }
  };

  const openRemoveFromCalendarModale = () => {
    openCalendarModal(REMOVE_FROM_CALENDAR_MODAL, { callback: removeFromMyCalendar });
  }

  const removeFromMyCalendar = () => {
    const updatedEvents = { ...user.events };
    delete updatedEvents[id];

    if (user) {
      updateUser(uid, { ...user, events: updatedEvents, })
        .then(() => {
          updateUserDetails(() => { closeCalendarModal() });
        })
        .catch((err) => {
          console.log(err);
          closeCalendarModal()
        })
    }
  };

  useEffect(() => {
    setisAdded(user?.events?.[id] || false);
  }, [id, user]);

  const deleteEventHandler = () => {
    deleteEventById(id).then(() => {
      setNotification({
        success: t("eventDeleted"),
      });
    })
      .catch((err) => {
        setNotification({
          error: t("eventDeletionFailed"),
        });
        console.log(err);
      });
  }

  const approveEventHandler = () => {
    let { id, ...eventDB } = event;
    eventDB = { ...eventDB, status: EVENT_STATUS.APPROVED };

    updateEventById(id, eventDB)
      .then(() => {
        setNotification({
          success: t("eventApproved"),
        });
      })
      .catch((err) => {
        setNotification({
          error: t("eventApproveFailed"),
        });
        console.log(err);
      });
  };

  return (
    <Wrapper $isCancelled={isCancelled}>
      {isCancelled && <Cancelled>
        {t("cancelled")}
      </Cancelled>}
      <Content>
        <Header>
          <Title>{name}</Title>
          {!isUpdate && (
            <SwitchWrapper>
              <Switch
                text={t("going")}
                onChange={(checked) => {
                  if (!user) {
                    closeCalendarModal();
                    navigate("/login", { state: { accessFeatures: true } })
                    return;
                  }
                  if (checked) {
                    addToMyCalendar();
                  } else {
                    openRemoveFromCalendarModale();
                  }
                }}
                value={isAdded}
                disabled={(loadingStates.add || loadingStates.remove)}
              />
              {(loadingStates.add || loadingStates.remove) && <CircleLoader position="absolute" top="100%" left="50%" translateX="-50%" translateY="25%" />}
            </SwitchWrapper>
          )}

          {!isUpdate &&
            <Actions>
              {user?.role === ADMIN && (
                <>
                  {(event.status === EVENT_STATUS.APPROVAL_PENDING || event.status === EVENT_STATUS.CANCELLED) &&
                    <Button
                      iconName="tick"
                      color="#BBD900"
                      onClick={() => approveEventHandler()}
                      padding={"0px"}
                    />}
                  <Button
                    iconName="pen"
                    color="#FC8A17"
                    onClick={() => openCalendarModal(EDIT_EVENT_MODAL, { event })}
                    padding={"0px"}
                  />
                  <Button
                    iconName="delete"
                    color="#ED6A5A"
                    onClick={deleteEventHandler}
                    padding={"0px"}
                  />
                </>
              )}
              <Button
                iconName="update"
                color="#6689A1"
                onClick={() => {
                  if (user) {
                    openCalendarModal(UPDATE_EVENT_MODAL, { event })
                  } else {
                    closeCalendarModal();
                    navigate("/login", { state: { accessFeatures: true } })
                  }
                }}
                padding={"0px"}
              />
              <Copy id={id} />
            </Actions>
          }
        </Header>
        <Types>
          {type?.swim && <Icon name="swim" size="65px" />}
          {type?.bike && <Icon name="bike" size="65px" />}
          {type?.run && <Icon name="run" size="65px" />}
          {type?.duathlon && <Icon name="duathlon" size="65px" />}
          {type?.triathlon && <Icon name="triathlon" size="65px" />}
        </Types>
        <Details>
          <Group>
            <Detail>
              <Icon name="start" />
              {format(startDate, "dd MMMM yyyy", { locale: timeLang })}
            </Detail>
            <Detail>
              <Icon name="stop" />
              {format(endDate, "dd MMMM yyyy", { locale: timeLang })}
            </Detail>
          </Group>
          <Group>
            <Detail>
              <Icon name="location" />
              {location.name}
            </Detail>
            {link && (
              <Detail>
                <Icon name="link" />
                <a href={link} target="_blank" rel="nofollow noreferrer">
                  {link}
                </a>
              </Detail>
            )}
          </Group>
          <Perks>
            {isKidRace && (
              <Perk>
                <Icon name="kids" size="30px" alt="Kids Race" />
                {t("kidsEvent")}
              </Perk>
            )}
            {isParaRace && (
              <Perk>
                <Icon name="para" size="30px" alt="Para Race" />
                {t("paraEvent")}
              </Perk>
            )}
            {isRelayRace && (
              <Perk>
                <Icon name="relay" size="30px" alt="Relay Race" />
                {t("relayEvent")}
              </Perk>
            )}
          </Perks>
        </Details>
        {description && (
          <DescriptionWrapper>
            <Description $isExpanded={isDescriptionExpanded}>
              {description}
            </Description>
            {description.split("\n").length > 4 && (
              <SeeMoreButton onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}>
                {isDescriptionExpanded ? t("seeLess") : t("seeMore")}
              </SeeMoreButton>
            )}
          </DescriptionWrapper>
        )}
        <Requirements eventId={id} requirements={requirements} fullfilled={fullfilled} showRequirements={true} showFullfilled={isAdded} />
      </Content>
      {isAdminPage && addedBy && <OwnerCard owner={addedBy} showAddedBy={true} />}
      {isAdminPage && updatedBy && <OwnerCard owner={updatedBy} showUpdatedBy={true} />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 20px;
  padding: 34px 30px;
  box-sizing: border-box;
  border: 1px solid #d7d7d7;
  border-radius: 14px;
  display: flex;
  align-items: center;
  fill: #303134;
  flex-grow: 1;
  background-color: ${({ $isCancelled }) => $isCancelled ? "#EEEEEE" : ""};
  position: relative;
  color: #303134;

  @media (max-width: 760px) {
    padding: 20px 16px;
    flex-direction: column;
    gap: 20px;
  }
`;

const Types = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-self: center;

  @media (max-width: 760px) {
    align-self: center;
    gap: 15px;
  }

  @media (max-width: 480px) {
    gap: 7px;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Actions = styled.div`
  display: flex;
  gap: 5px;
  z-index: 1;
  align-self: end;

  @media (max-width: 760px) {
    flex-direction: column-reverse;
  }
`;

const Title = styled(Heading3)`
  flex-grow: 1;
`;

const DescriptionWrapper = styled.div`
  position: relative;
`;

const Description = styled(Body2)`
  white-space: pre-wrap;
  min-width: 100%;
  width: 0;
  letter-spacing: 0.02rem;
  color: #717579;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ $isExpanded }) => ($isExpanded ? "none" : "4")};
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
`;

const SeeMoreButton = styled(Body1)`
  cursor: pointer;
  margin-top: 10px;
`;

const Details = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width: 760px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 200px;
  color: #303134;

  @media (max-width: 760px) {
    max-width: 100%;
  }
`;

const Perks = styled(Body2)`
  flex-grow: 1;
`;

const Perk = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const Detail = styled(Body2)`
  display: flex;
  gap: 15px;
  align-items: center;

  a {
    text-decoration: none;
    color: inherit;
    word-break: break-all;
  }
`;

const Cancelled = styled.div`
  border-radius: 14px;
  font-family: "Cuprum";
  font-style: normal;
  font-weight: 700;
  font-size: 65px;
  background-color:rgba(251, 251, 251, 0.45);
  width: 100%;
  height: 100%;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

position: absolute;
margin: auto 0px;
`

const SwitchWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 3px;
position:relative;`

export default EventCard;
