import React from 'react'
import styled from 'styled-components'
import Schedule from "../../features/Schedule/Schedule"
import { useTranslation } from 'react-i18next'
import Calendar from '../../features/calendar/Calendar'
import AddEventBanner from '../../features/events/components/AddEventBanner'
import { useCalendar } from '../../providers/CalendarProvider'
import Loader from '../../libs/reuse/components/Loader'
import { Helmet } from 'react-helmet-async'

function CalendarPage() {
    const { t } = useTranslation("schedule")
    const {
        date,
        weekArray,
        weeks,
        filterType,
        setFilterType,
        filteredEvents,
        prevMonth,
        nextMonth,
        setToday,
        addEvent,
        getTodayEvents,
        dayIsReserved,
        loading,
        events,
    } = useCalendar();

    const calendarProps = {
        date,
        weekArray,
        weeks,
        filterType,
        setFilterType,
        filteredEvents,
        prevMonth,
        nextMonth,
        setToday,
        addEvent,
        getTodayEvents,
        dayIsReserved,
    }

    if (loading) return <Loader />

    return (
        <>
            <Helmet>
                {/* SEO Meta Tags */}
                <title>iRace Club - Един календар. Твоите състезания</title>
                <meta
                    name="description"
                    content="Твоят онлайн календар за аматьорски състезания и спортни събития по колоездене, плуване и бягане в България (и наблизо)."
                />
                <meta
                    name="keywords"
                    content="iRace, racing events, racing platform, racing community, racing competitions"
                />

                {/* Open Graph Tags */}
                <meta property="og:site_name" content="iRace" />
                <meta property="og:type" content="website" />
                <meta
                    property="og:title"
                    content="iRace Club - Един календар. Твоите състезания"
                />
                <meta
                    property="og:description"
                    content="Твоят онлайн календар за аматьорски състезания и спортни събития по колоездене, плуване и бягане в България (и наблизо)."
                />
                <meta property="og:image" content={process.env.REACT_APP_OG_IMAGE} />
                <meta property="og:url" content="https://irace.club" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta
                    name="twitter:title"
                    content="iRace Club - Един календар. Твоите състезания"
                />
                <meta
                    name="twitter:description"
                    content="Твоят онлайн календар за аматьорски състезания и спортни събития по колоездене, плуване и бягане в България (и наблизо)."
                />
                <meta
                    name="twitter:image"
                    content="https://irace.club/images/share-image.jpg"
                />

                {/* Canonical URL */}
                <link rel="canonical" href="https://irace.club" />
            </Helmet>
            <Wrapper>
                <Section>
                    <Calendar calendarProps={calendarProps} />
                    <Schedule
                        events={events}
                        title={t("suggestedEvents")}
                        show={{ pinned: true, upcomming: false, myEvents: false }} />
                </Section>
                <AddEventBanner />
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
flex-grow:1;
`

const Section = styled.div`
display: flex;
gap: 20px;

@media (max-width: 1200px) {
    flex-direction: column;
  }
`

export default CalendarPage