import React from 'react'
import styled from 'styled-components'
import { Body1, Body2, Heading4 } from '../../libs/reuse/components/texts/textStyles'
import Login from '../Auth/Login'
import { useTranslation } from 'react-i18next'

function AddEventPage() {
    const { t } = useTranslation("addEvent");
    return (
        <Wrapper>
            <Heading4>{t("welcome")}</Heading4>
            <Content>
                <Body1 style={{ paddingInlineStart: '40px' }}>{t("threeSteps")}:</Body1>
                <Body2>
                    <ol>
                        <li>{t("createAccount")}</li>
                        <li>{t("clickAddEvent")}</li>
                        <li>{t("submitEvent")}</li>
                    </ol>
                </Body2>
            </Content>
            <Body1><a href='/contact'>{t("contactUs")}</a></Body1>
            <Login />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    gap: 20px;
    padding: 30px 15px;
    color: #303134;

    a {
        text-decoration: none;
        color: #303134;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export default AddEventPage