import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useModals } from "../../providers/ModalsProvider";
import {
  ADD_EVENT_MODAL,
  DAILY_EVENTS_MODAL,
  DELETE_USER_MODAL,
  EDIT_EVENT_MODAL,
  EVENT_DETAILS_MODAL,
  REMOVE_FROM_CALENDAR_MODAL,
  UPDATE_EVENT_MODAL,
} from "../../constants/modals";
import AddEvent from "./components/AddEvent";
import EditEvent from "./components/EditEvent";
import DailyEvents from "./components/DailyEvents";
import DeleteUser from "./components/DeleteUser";
import EventDetails from "./components/EventDetails";
import UpdateEvent from "./components/UpdateEvent";
import Scroll from "../../libs/reuse/components/Scroll";
import { Heading2 } from "../../libs/reuse/components/texts/textStyles";
import Button from "../../libs/reuse/components/Button";
import { useTranslation } from "react-i18next";
import RemoveFromMyCalendar from "./components/RemoveFromMyCalendar";

function MiddleModale() {
  const { calendarModal, closeCalendarModal, closeAllCalendarModals, modalStackLength } = useModals();
  const [yOffset, setYOffset] = useState(0);
  const { t } = useTranslation("middleModale");

  const title = useMemo(() => {
    if (!calendarModal?.modal) return "";

    switch (calendarModal.modal) {
      case ADD_EVENT_MODAL:
        return t("addNewEvent");
      case EDIT_EVENT_MODAL:
        return t("editEvent");
      case UPDATE_EVENT_MODAL:
        return t("updateEvent");
      case DAILY_EVENTS_MODAL:
        return t("dailyEvents");
      case EVENT_DETAILS_MODAL:
        return t("eventDetails");
      case DELETE_USER_MODAL:
        return t("deleteAccount");
      case REMOVE_FROM_CALENDAR_MODAL:
        return t("removeEvent");
      default:
        return "";
    }
  }, [calendarModal?.modal, t]);

  useEffect(() => {
    setYOffset(window.scrollY);
  }, []);

  // Handle background click - close all modals if clicked outside
  const handleBackgroundClick = () => {
    closeAllCalendarModals();
  };

  return (
    <GrayOut
      onClick={handleBackgroundClick}
    >
      <WhiteBox
        $yOffset={yOffset}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <Header>
          <Content>
            <Title>{title}</Title>
          </Content>
          <Button
            iconName="close"
            color="#ED6A5A"
            onClick={() => closeCalendarModal()}
            padding={"12px 0px"}
          />
        </Header>
        <Scroll>
          {calendarModal?.modal === ADD_EVENT_MODAL && <AddEvent />}
          {calendarModal?.modal === EDIT_EVENT_MODAL && <EditEvent />}
          {calendarModal?.modal === UPDATE_EVENT_MODAL && <UpdateEvent />}
          {calendarModal?.modal === DAILY_EVENTS_MODAL && <DailyEvents />}
          {calendarModal?.modal === EVENT_DETAILS_MODAL && <EventDetails />}
          {calendarModal?.modal === DELETE_USER_MODAL && <DeleteUser />}
          {calendarModal?.modal === REMOVE_FROM_CALENDAR_MODAL && <RemoveFromMyCalendar />}
        </Scroll>
      </WhiteBox>
    </GrayOut>
  );
}

const GrayOut = styled.div`
  position: fixed; /* Ensures the overlay covers the entire screen */
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 500; /* Ensures modal appears above everything */
`;

const WhiteBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 40px rgba(0, 0, 0, 0.52);
  border-radius: 20px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 800px;
  width: 100%;
  box-sizing: border-box;
  z-index: 500;
  
  @media (max-width: 480px) {
    padding: 15px;
  }

  max-height: 100vh; /* Ensures content does not exceed viewport height */
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled(Heading2)`
  color: #303134;
`;

export default MiddleModale;
