import React from "react";
import styled from "styled-components";
import Events from "../../features/events/Events";
import AddEventBanner from "../../features/events/components/AddEventBanner";

function EventsPage({ toApprove }) {
    return (
        <Wrapper>
            <Events toApprove={toApprove} />
            <AddEventBanner />
        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
`;

export default EventsPage;
