import { useContext, createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { auth } from "../firebase-config";
import {
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  updatePassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { RACER } from "../constants/roles";
import { LIGHT } from "../constants/themes";
import { EN } from "../constants/lang";
import { useModals } from "./ModalsProvider";
import { useNotification } from "./NotificationProvider";
import {
  addUser,
  deleteUserById,
  getUserById,
} from "../features/users/api/firebase";
import { useTranslation } from "react-i18next";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const { closeHeaderModal, closeCalendarModal } = useModals();
  const { setNotification } = useNotification();
  const { t } = useTranslation("profile");

  const [id, setId] = useState();
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("site") || "");
  const [error, setError] = useState("");

  // Loading states for various operations
  const [isInitializing, setIsInitializing] = useState(true);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const [isDeletingProfile, setIsDeletingProfile] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);

  useEffect(() => {
    setIsInitializing(true);

    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            getUserById(user.uid)
              .then((res) => {
                const userData = res.data();
                setUser(userData);
                setId(user.uid);
                setToken(user.accessToken);
                localStorage.setItem("site", user.accessToken);
              })
              .catch(errorHandler)
              .finally(() => setIsInitializing(false));
          } else {
            setUser(null);
            setId(null);
            setToken("");
            localStorage.removeItem("site");
            setIsInitializing(false);
          }
        });
      })
      .catch((err) => {
        errorHandler(err);
        setIsInitializing(false);
      });
  }, []);

  const errorHandler = (err) => {
    console.log(err);
    setError(err.code);
  };

  const resetError = () => {
    setError("");
  };

  const handleUser = (userCredential, navigateTo) => {
    const {
      user: { emailVerified, uid, accessToken },
    } = userCredential;

    if (emailVerified) {
      return getUserById(uid)
        .then((res) => {
          const user = res.data();
          setUser(user);
          setId(uid);
          setToken(accessToken);
          localStorage.setItem("site", accessToken);
          navigate(navigateTo);
          return user;
        })
        .catch(errorHandler);
    } else {
      return sendEmailVerification(auth.currentUser)
        .then(() => {
          setNotification({ success: t("checkEmail") });
          return null;
        })
        .catch(errorHandler);
    }
  };

  const register = (user) => {
    setIsRegistering(true);

    setPersistence(auth, browserLocalPersistence)
      .then(() =>
        createUserWithEmailAndPassword(auth, user.email, user.password)
      )
      .then((userCredential) => {
        auth.languageCode = user.lang;
        sendEmailVerification(auth.currentUser)
          .then(() => {
            delete user.password;
            addUser(userCredential.user.uid, user)
              .then(() => {
                setToken(userCredential.user.accessToken);
                localStorage.setItem("site", userCredential.user.accessToken);
                setNotification({ success: t("checkEmail") });
                navigate("/login");
              })
              .catch((err) => {
                deleteUser(userCredential.user).catch(errorHandler);
                errorHandler(err);
              })
              .finally(() => setIsRegistering(false));
          })
          .catch((err) => {
            errorHandler(err);
            setIsRegistering(false);
          });
      })
      .catch((err) => {
        errorHandler(err);
        setIsRegistering(false);
      });
  };

  const login = (email, password) => {
    setIsLoggingIn(true);

    setPersistence(auth, browserLocalPersistence)
      .then(() => signInWithEmailAndPassword(auth, email, password))
      .then((userCredential) => handleUser(userCredential, "/"))
      .catch(errorHandler)
      .finally(() => setIsLoggingIn(false));
  };

  const loginWithProvider = (provider) => {
    setIsLoggingIn(true);

    setPersistence(auth, browserLocalPersistence)
      .then(() => signInWithPopup(auth, provider))
      .then((result) => {
        const credential =
          provider.providerId === "facebook.com"
            ? FacebookAuthProvider.credentialFromResult(result)
            : GoogleAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const user = result.user;
        const [firstName, lastName] = user.displayName.split(" ");
        const userDB = {
          firstName,
          lastName,
          email: user.email,
          photoURL: user.photoURL,
          role: RACER,
          theme: LIGHT,
          lang: EN,
          events: {}, // Changed from array to object for event tracking
        };

        getUserById(user.uid)
          .then((res) => {
            if (res.exists()) {
              const user = res.data();
              setUser(user);
              setId(res.uid);
              setToken(user.accessToken);
              localStorage.setItem("site", user.accessToken);
              navigate("/");
            } else {
              addUser(user.uid, userDB)
                .then(() => {
                  setUser(userDB);
                  setId(user.uid);
                  setToken(accessToken);
                  localStorage.setItem("site", accessToken);
                  navigate("/");
                })
                .catch((err) => {
                  deleteUser(user).catch(errorHandler);
                  errorHandler(err);
                });
            }
          })
          .catch(errorHandler)
          .finally(() => setIsLoggingIn(false));
      })
      .catch((err) => {
        errorHandler(err);
        setIsLoggingIn(false);
      });
  };

  const logout = () => {
    setIsLoggingOut(true);

    signOut(auth)
      .then(() => {
        setUser(null);
        setId(null);
        setToken("");
        localStorage.removeItem("site");
        navigate("/");
        closeHeaderModal();
      })
      .catch(errorHandler)
      .finally(() => setIsLoggingOut(false));
  };

  const updateUserDetails = (callback) => {
    setIsUpdatingUser(true);

    return getUserById(id)
      .then((res) => {
        const userData = res.data();
        setUser(userData);
        setId(id);
        setToken(userData.accessToken);
        localStorage.setItem("site", userData.accessToken);
        return userData; // Return for promise chaining
      })
      .catch((err) => {
        errorHandler(err);
        throw err; // Rethrow for proper promise handling
      })
      .finally(() => {
        setIsUpdatingUser(false);
        if (typeof callback === "function") {
          callback();
        }
      });
  };

  const updateUserPassword = async (password) => {
    setIsUpdatingPassword(true);

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      await updatePassword(user, password);
      return true;
    } catch (err) {
      errorHandler(err);
      throw err;
    } finally {
      setIsUpdatingPassword(false);
    }
  };

  const deleteUserProfile = () => {
    setIsDeletingProfile(true);

    const auth = getAuth();
    const user = auth.currentUser;

    deleteUser(user)
      .then(() => {
        deleteUserById(user.uid).then(() => {
          logout();
          closeCalendarModal();
          navigate("/");
          setNotification({ success: t("userDeleted") });
        });
      })
      .catch(errorHandler)
      .finally(() => setIsDeletingProfile(false));
  };

  const resetUserPassword = (email, lang) => {
    setIsResettingPassword(true);

    const auth = getAuth();
    auth.languageCode = lang;

    sendPasswordResetEmail(auth, email)
      .then(() => {
        navigate("/login");
        setNotification({ success: t("passwordReset") });
      })
      .catch(errorHandler)
      .finally(() => setIsResettingPassword(false));
  };

  return (
    <AuthContext.Provider
      value={{
        // State
        token,
        user,
        id,
        error,

        // Loading states
        isInitializing,
        isRegistering,
        isLoggingIn,
        isLoggingOut,
        isUpdatingUser,
        isUpdatingPassword,
        isDeletingProfile,
        isResettingPassword,

        // Methods
        register,
        login,
        loginWithProvider,
        logout,
        updateUserDetails,
        updateUserPassword,
        deleteUserProfile,
        resetUserPassword,
        resetError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
