import React from 'react'
import styled from 'styled-components'
import { Heading2, Heading6 } from '../../../libs/reuse/components/texts/textStyles'

import { NavLink, Route, Routes } from 'react-router';
import BannerSettings from './BannerSettings';
import { useTranslation } from 'react-i18next';
import VerticalMenu from '../../../libs/reuse/components/VerticalMenu';

export const ID = {
    TOP: "topBanner",
    CALENDAR: "calendarBanner",
    SIDE1: "sideBanner1",
    SIDE2: "sideBanner2",
}

function AssignBanners() {
    const { t } = useTranslation("banners")

    const optionsMenu = [
        { path: "/admin/banners/assign", label: t("topBanner") },
        { path: "/admin/banners/assign/calendar-banner", label: t("calendarBanner") },
        { path: "/admin/banners/assign/side-banner1", label: t("sideBanner1") },
        { path: "/admin/banners/assign/side-banner2", label: t("sideBanner2") },
    ]

    return (
        <Wrapper>
            <VerticalMenu options={optionsMenu} />
            <Banners>
                <Heading2>{t("assignBanners")}</Heading2>
                <Routes>
                    <Route path="" element={<BannerSettings bannerId={ID.TOP} />} />
                    <Route path="calendar-banner" element={<BannerSettings bannerId={ID.CALENDAR} />} />
                    <Route path="side-banner1" element={<BannerSettings bannerId={ID.SIDE1} />} />
                    <Route path="side-banner2" element={<BannerSettings bannerId={ID.SIDE2} />} />
                </Routes>
            </Banners>
        </Wrapper>
    )
}

const Wrapper = styled.div`
display: flex;
flex-direction: row;
gap: 30px;
align-items: flex-start;
`

const Banners = styled.div`
display: flex;
flex-direction: column;
gap: 35px;
width: 100%;
`



export default AssignBanners