import React from "react";
import styled from "styled-components";
import LeftSideBanner from "../../features/banners/LeftSideBanner";
import kids from "../../assets/images/kids.svg";
import Navigation from "../Header/components/Navigation";
import { Body2 } from "../../libs/reuse/components/texts/textStyles";
import { Link, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../providers/AuthProvider";
import { useModals } from "../../providers/ModalsProvider";
import { ADD_EVENT_MODAL } from "../../constants/modals";
import DonateButton from "./components.jsx/Donate";
import { useLocale } from "../../providers/LocaleProvider";
import advertisePDFBG from "../../assets/pdf/iRace_Advertise_Prices_BG.pdf";

function Sidebar() {
  const { user } = useAuth();
  const { openCalendarModal } = useModals();
  const navigate = useNavigate();
  const { t } = useTranslation("sidebar");
  const { lang } = useLocale();

  const advertiseLink = lang === "en" ? "https://simplebooklet.com/iracemediakit" : advertisePDFBG;

  return (
    <Wrapper>
      <Nav>
        <Navigation />
      </Nav>
      <img src={kids} alt="Kids" />
      <Banners>
        <LeftSideBanner />
      </Banners>
      <Footer>
        <FooterLink to="about-us">
          <Body2>{t('aboutUs')}</Body2>
        </FooterLink>
        <FooterLink to="contact">
          <Body2>{t('contact')}</Body2>
        </FooterLink>
        <FooterLinkButton onClick={(e) => {
          e.stopPropagation();
          user ? openCalendarModal(ADD_EVENT_MODAL) : navigate("add-event")
        }}>
          <Body2>{t('yourEventHere')}</Body2>
        </FooterLinkButton>
        <Body2><a href={advertiseLink} target="_blank" rel="nofollow noreferrer">{t('advertise')}</a></Body2>
        <FooterLink to="privacy-policy">
          <Body2>{t('privacyPolicy')}</Body2>
        </FooterLink>
        {/* <FooterLink to="cookie-policy">
          <Body2>{t('cookiePolicy')}</Body2>
        </FooterLink>
        <FooterLink to="disclaimer">
          <Body2>{t('disclaimer')}</Body2>
        </FooterLink> */}
        {/* <DonateButton /> */}
      </Footer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #eeeeee;
  flex-grow: 1;
  gap: 20px;

  @media (max-width: 960px) {
    border: none;
    }

  img {
    @media (max-width: 960px) {
      width: 40%;
      align-self: center;
    }

    @media (max-width: 780px) {
      width: 60%;
      align-self: center;
    }
  }
`;

const Nav = styled.div`
margin-top: 20px;
display: flex;
flex-direction: column;
gap: 10px;
  @media (max-width: 960px) {
    display: none;
  }
`;

const Banners = styled.div`
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;

  img{
    width: 100%;
  }

  @media (max-width: 960px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 23px;
  color: #717579;

  a{
    color: #303134;
    text-decoration: none;
  }
`;

const FooterLink = styled(Link)`
text-decoration: none;
display: flex;
gap: 7px;
align-items: center;
color: #303134;
`

const FooterLinkButton = styled.div`
cursor: pointer;
color: #303134;
`

export default Sidebar;
