import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getAllUsers } from "../../users/api/firebase";
import { Heading3 } from "../../../libs/reuse/components/texts/textStyles";
import { useTranslation } from "react-i18next";
import EventCard from "../../event/EventCard";

function EventsList({ events }) {
  const { t } = useTranslation("events");
  const [users, setUsers] = useState({});

  useEffect(() => {
    getAllUsers()
      .then((res) => {
        let usersObj = {};
        res.docs.forEach((doc) => {
          usersObj[doc.id] = { ...doc.data() };
        });
        setUsers(usersObj);
      })
      .catch((err) => console.log(err));
  }, []);

  if (!events?.length) return <NoEvents>{t("noEvents")}</NoEvents>;

  return (
    <Wrapper>
      {events?.map((event) => (
        <EventCard key={event.id} event={event} addedBy={users[event.addedBy]} />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const NoEvents = styled(Heading3)`
  margin-top: 30px;
  align-self: center;
`;

export default EventsList;
