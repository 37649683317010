import { CircularProgress } from '@mui/material'
import React from 'react'
import styled from 'styled-components';

function CircleLoader({
    size = 18,
    top = "50%",
    left = "100%",
    translateX = "0",
    translateY = "-50%",
    position = "absolute",
    color = "#6689A1"
}) {
    return (
        <LoaderWrapper
            $top={top}
            $left={left}
            $translateX={translateX}
            $translateY={translateY}
            $position={position}
        >
            <CircularProgress size={size} style={{ color }} />
        </LoaderWrapper>
    )
}

const LoaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    
    /* Use transient props (with $ prefix) to prevent them from being passed to the DOM */
    position: ${({ $position }) => $position};
    top: ${({ $top }) => $top};
    left: ${({ $left }) => $left};
    transform: translate(${({ $translateX }) => $translateX}, ${({ $translateY }) => $translateY});

    /* Make size responsive to the CircularProgress size */
    min-width: ${({ size }) => size ? `${size * 2}px` : '36px'};
    min-height: ${({ size }) => size ? `${size}px` : '18px'};
`;

export default CircleLoader