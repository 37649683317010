import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import ErrorForm from '../../../libs/reuse/components/ErrorForm';
import Icon from '../../../libs/reuse/components/Icon';
import { useTranslation } from 'react-i18next';

function EventSelector({ input, setInput, error, setError }) {
  const { t } = useTranslation("events");

  // Define the event types and corresponding icon names.
  const eventTypes = [
    { key: 'swim', icon: 'swim' },
    { key: 'bike', icon: 'bike' },
    { key: 'run', icon: 'run' },
    { key: 'duathlon', icon: 'duathlon' },
    { key: 'triathlon', icon: 'triathlon' },
  ];

  // Local state to track if the user has interacted with the buttons.
  const [isTouched, setIsTouched] = useState(false);

  // Toggle a given event type: if it exists, remove it; if not, add it.
  const toggleType = (key) => {
    if (!isTouched) setIsTouched(true);
    setInput((prev) => ({
      ...prev,
      [key]: prev[key] ? null : [] // Use an empty array to mark as active
    }));
  };

  // Check error state on initial render and subsequent updates
  useEffect(() => {
    const isEmptyType = Object.keys(input).length === 0 || Object.values(input).every(value => value === null);
    setError(isTouched && isEmptyType && t("emptyType"));
  }, [input, isTouched, setError]);

  return (
    <>
      <Types>
        {eventTypes.map(({ key, icon }) => (
          <ActivityButton key={key}>
            <ActivityTypeButton
              type='button'
              className={input[key] ? "active" : ""}
              onClick={() => toggleType(key)}
            >
              <Icon name={icon} size="35px" />
            </ActivityTypeButton>
          </ActivityButton>
        ))}
      </Types>
      {error && (
        <ErrorForm error={error || t("emptyType")} />
      )}
    </>
  )
}

const Types = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
`;

const ActivityButton = styled.div`
  display: flex;
  background-color: #E6ECEF;
  border-radius: 16px;
  fill: #717579;
  align-self: end;
  justify-content:center;

  @media (max-width: 1200px) {
    align-self: start;
  }
`;

const ActivityTypeButton = styled.button`
  font: inherit;
  background-color: transparent;
  border: none;
  line-height: 24px;
  padding: 12px 28px;
  cursor: pointer;
  transition: 0.5s;
  color: #717579;
  fill: #717579;
  opacity: 0.8;
  font: inherit;

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
    color: #303134;
    background-color: #ffffff;
    box-shadow: 0px 6px 20px rgba(181, 181, 181, 0.42);
    border-radius: 16px;
  }

  @media (max-width: 760px) {
    padding: 12px 15px;
  }

  @media (max-width: 480px) {
    padding: 12px 12px;
  }
`;

export default EventSelector