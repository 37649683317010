import React from "react";
import styled from "styled-components";
import Icon from "../../../libs/reuse/components/Icon";
import { EVENT_DETAILS_MODAL } from "../../../constants/modals";
import { useModals } from "../../../providers/ModalsProvider";
import { Heading6, Heading7 } from "../../../libs/reuse/components/texts/textStyles";

function MyEvent({ event }) {
  const { openCalendarModal } = useModals();
  return (
    <Wrapper
      onClick={() => {
        openCalendarModal(EVENT_DETAILS_MODAL, { event });
      }}
    >
      <Types>
        {event.type.isSwim && <Icon name="swim" size="24px" />}
        {event.type.isBike && <Icon name="bike" size="24px" />}
        {event.type.isRun && <Icon name="run" size="24px" />}
      </Types>
      <Details>
        <Title>{event.name}</Title>
        <Location>
          <Icon name="location" size="15px" />
          {event.location}
        </Location>
      </Details>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 10px 20px;
  padding: 20px;
  display: flex;
  border-radius: 16px;
  color: #303134;
  fill: #303134;
  background-color: #E6ECEF;

  gap: 16px;
  position: relative;
  cursor: pointer;
`;

const Types = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
`;

const Details = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled(Heading6)`
`;

const Location = styled(Heading7)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default MyEvent;
