import React from "react";
import styled from "styled-components";
import { useModals } from "../../../providers/ModalsProvider";
import ButtonContained from "../../../libs/reuse/components/ButtonContained";
import { useAuth } from "../../../providers/AuthProvider";
import { Heading6 } from "../../../libs/reuse/components/texts/textStyles";
import { useTranslation } from "react-i18next";

function DeleteUser() {
  const { deleteUserProfile } = useAuth();
  const { closeCalendarModal } = useModals();
  const { t } = useTranslation("middleModale");

  const deleteUserHandler = () => {
    deleteUserProfile();
  };

  return (
    <Wrapper>
      <Content>
        <Heading6>{t("consent")}</Heading6>
        <ButtonContained
          text={t("no")}
          color="#6689A1"
          onClick={() => closeCalendarModal()}
        />
        <ButtonContained
          text={t("yes")}
          color="#ED6A5A"
          onClick={() => deleteUserHandler()}
        />
      </Content>
    </Wrapper>
  );
}

export default DeleteUser;

const Wrapper = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;


