import styled from "styled-components";

export const Heading1 = styled.span`
  font-family: "Cuprum";
  font-weight: 700;
  font-size: 2rem; /* 32px */
`;

export const Heading2 = styled.span`
  font-family: "Cuprum";
  font-weight: 700;
  font-size: 1.5rem; /* 24px */
`;

export const Heading3 = styled.span`
  font-family: "Cuprum";
  font-weight: 700;
  font-size: 1.25rem; /* 20px */
`;

export const Heading4 = styled.span`
  font-family: "Cuprum";
  font-weight: 600;
  font-size: 1.125rem; /* 18px */
`;

export const Heading5 = styled.span`
  font-family: "Cuprum";
  font-weight: 500;
  font-size: 1.125rem; /* 18px */
`;

export const Heading6 = styled.span`
  font-family: "Cuprum";
  font-weight: 400;
  font-size: 1.125rem; /* 18px */
`;

export const Heading7 = styled.span`
  font-family: "Cuprum";
  font-weight: 400;
  font-size: 1rem; /* 16px */
`;

export const Body1 = styled.span`
  font-family: "Cuprum";
  font-weight: 700;
  font-size: 1rem; /* 16px */
`;

export const Body2 = styled.span`
  font-family: "Cuprum";
  font-weight: 400;
  font-size: 1rem; /* 16px */
`;

export const Subtitle = styled.span`
  font-family: "Cuprum";
  font-weight: 400;
  font-size: 0.875rem; /* 14px */
`;
