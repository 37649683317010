import React, { useEffect, useState } from 'react'// Adjust path accordingly
import Lottie from 'lottie-react';
import loader from "../../../assets/animations/loader.json";
import styled from 'styled-components';

function Loader({ width = 200, height = 200, loop = true, delay = 300 }) {
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setShowLoader(true), delay);
        return () => clearTimeout(timer); // Cleanup on unmount
    }, [delay]);

    return (
        showLoader &&
        <Wrapper>
            <div style={{ width, height }}>
                <Lottie animationData={loader} loop={loop} />
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow:1;
    `;


export default Loader