import React from 'react';
import styled from 'styled-components';
import logo from "../../../assets/logos/iRaceLogo3.webp";
import Sidebar from '../../../page/Sidebar/Sidebar';
import MiddleModale from '../../../features/middle-screen-modale/MiddleModale';
import Header from '../../../page/Header/Header';
import { useModals } from '../../../providers/ModalsProvider';
import { Outlet } from 'react-router';
import TopBanner from '../../../features/banners/TopBanner';

const Layout = ({ children }) => {
  const { calendarModal } = useModals();

  return (
    <Wrapper>
      {calendarModal && <MiddleModale />}
      <LeftContainer>
        <Logo>
          <Image onClick={() => window.location.replace("/")}>
            <img src={logo} alt="iRace logo" />
          </Image>
        </Logo>
        <Sidebar />
      </LeftContainer>
      <RightContainer>
        <Header />
        <Container>
          <Body>
            <TopBanner />
            <Content>
              <Outlet />
            </Content>
          </Body>
        </Container>
      </RightContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1920px;
  min-height: 100vh;
  display: flex;
  flex-direction: row;

  @media (max-width: 960px) {
    flex-direction: column-reverse;
    gap: 10px;
  }
`;

const LeftContainer = styled.div`
  max-width: 345px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  @media (max-width: 1600px) {
    width: 300px;
  }

  @media (max-width: 1200px) {
    width: 280px;
  }

  @media (max-width: 960px) {
    max-width: 100%;
    width: 100%;
  }
`;

const Logo = styled.div`
  border-right: 1px solid #eeeeee;
  border-radius: 2px;
  display: flex;
  justify-content: center;

  @media (max-width: 960px) {
    display: none;
  }
`;

const Image = styled.div`
  padding: 31px 0px 0px 0px;
  max-width: 175px;
  object-fit: content;
  cursor: pointer;

  img {
    width: 100%;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`;

const Container = styled.div`
  display: flex;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 25px 50px;
  flex-grow: 1;

  @media (max-width: 1600px) {
    margin: 17px 35px;
  }

  @media (max-width: 1200px) {
    margin: 10px 20px;
  }

  @media (max-width: 480px) {
    margin: 5px 5px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  gap: 10px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export default Layout;