import React from "react";
import styled from "styled-components";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Heading5, Heading6 } from "./texts/textStyles";
import { useLocale } from "../../../providers/LocaleProvider";
import { enUS, bg } from 'date-fns/locale';
import Icon from "./Icon";
import ErrorForm from "./ErrorForm";

function DatePicker({ text, input, onChange, dateFormat = "dd/MM/yyyy", error, disabled }) {
  const { lang } = useLocale();
  let locale = enUS;

  switch (lang) {
    case "bg":
      dateFormat = "dd.MM.yyyy";
      locale = bg;
      break;
    case "en":
      dateFormat = "MM/dd/yyyy";
      locale = enUS;
      break;
    default:
      break;
  }

  return (
    <Wrapper>
      <Title>{text}</Title>
      <Box disabled={disabled}>
        <StyledDatePicker
          selected={input}
          onChange={onChange}
          dateFormat={dateFormat}
          locale={locale}
        />
        <Icon name="calendar" size="24px" />
      </Box>
      {error && <ErrorForm error={error} />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const Title = styled(Heading6)`
  text-transform: uppercase;
  /* Secondary Text */
  color: #717579;
`;

const Box = styled(Heading5)`
  border: 1px solid #d7d7d7;
  border-radius: 14px;
  padding: 7px 22px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  color: #303134;
  fill: #717579;
  align-items: center;
  background-color: ${({ disabled }) => (disabled ? "#EEEEEE" : "none")};
  &:focus-visible {
    border: 1px solid black;
    outline: none;
  }

  .react-datepicker-wrapper{
    width: 100%;
    
    input{
      width: 100%;
      box-sizing: border-box;
    }
  }

  .react-datepicker__tab-loop{
    position: absolute;
  }
`;

const StyledDatePicker = styled(ReactDatePicker)`
  font: inherit;
  border: none;
  
  &:focus-visible {
    outline: none;
  }
`;

export default DatePicker;