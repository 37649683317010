import React, { useEffect, useState } from "react";
import styled from "styled-components";
import EventCard from "../../event/EventCard";
import { useModals } from "../../../providers/ModalsProvider";
import { useCalendar } from "../../../providers/CalendarProvider";
import { useTranslation } from "react-i18next";
import { Heading2 } from "../../../libs/reuse/components/texts/textStyles";
import Loader from "../../../libs/reuse/components/Loader";

function DailyEvents() {
  const { calendarModal } = useModals();
  const { t } = useTranslation("middleModale");

  // Extract data from the calendarModal
  const day = calendarModal?.data?.day;

  const { filteredEvents, getTodayEvents } = useCalendar();
  const [dailyEvents, setDailyEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setDailyEvents(getTodayEvents(day, filteredEvents));
    setIsLoading(false);
  }, [day, filteredEvents, getTodayEvents]);

  return (
    <Wrapper>
      <Events>
        {isLoading ? <Loader height={250} /> :
          dailyEvents.length > 0 ? (
            dailyEvents.map((event) => <EventCard key={event.id} event={event} />)
          ) : (
            <Title>{t("noEvents")}</Title>
          )}
      </Events>
    </Wrapper>
  );
}

const Wrapper = styled.div`
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow: auto;
      `;

const Title = styled(Heading2)`
      /* Primary Text */
      color: #303134;
      `;

const Events = styled.div`
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow: auto;
      `;

export default DailyEvents;
