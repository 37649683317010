import React from "react";
import styled from "styled-components";
import Icon from "../../../libs/reuse/components/Icon";
import { useModals } from "../../../providers/ModalsProvider";
import { EVENT_DETAILS_MODAL } from "../../../constants/modals";
import { EVENT_TYPES } from "../../../constants/events";
import { Heading6, Heading7 } from "../../../libs/reuse/components/texts/textStyles";

function Event({ event }) {
  const { openCalendarModal } = useModals();
  return (
    <Wrapper
      $pinned={event.isPinned}
      onClick={() => {
        openCalendarModal(EVENT_DETAILS_MODAL, { event });
      }}
    >
      {event.isPinned && (
        <Pin>
          <Icon name="pin" />
        </Pin>
      )}
      <Types>
        {event.type[EVENT_TYPES.SWIM] && <Icon name="swim" size="24px" />}
        {event.type[EVENT_TYPES.BIKE] && <Icon name="bike" size="24px" />}
        {event.type[EVENT_TYPES.RUN] && <Icon name="run" size="24px" />}
        {event.type[EVENT_TYPES.DUATHLON] && <Icon name="duathlon" size="24px" />}
        {event.type[EVENT_TYPES.TRIATHLON] && <Icon name="triathlon" size="24px" />}
      </Types>
      <Details>
        <Title>{event.name}</Title>
        <Location>
          <Icon name="location" size="15px" />
          {event.location.name}
        </Location>
      </Details>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 10px 20px;
  padding: 20px;
  display: flex;
  border-radius: 16px;
  color: ${({ $pinned }) => ($pinned ? "#ffffff" : "#303134")};
  fill: ${({ $pinned }) => ($pinned ? "#ffffff" : "#303134")};
  background-color: ${({ $pinned }) => ($pinned ? "#6689A1" : "#E6ECEF")};

  gap: 16px;
  position: relative;
  cursor: pointer;
`;

const Types = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
`;

const Details = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled(Heading6)`
`;

const Location = styled(Heading7)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Pin = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

export default Event;
