import React from "react";
import styled from "styled-components";
import ErrorForm from "../../../libs/reuse/components/ErrorForm";
import { firestoreErrors } from "../../../errors/firestoreErrors";
import { useEvents } from "../hooks/useEvents";
import { useTranslation } from "react-i18next";
import { useUpdates } from "../../updates/hooks/useUpdates";
import EventForm from "../../event-form/EventForm";

function UpdateEvent() {
  const {
    id,
    startDate,
    endDate,
    name,
    location,
    description,
    link,
    type,
    isPinned,
    errors,
    isKidRace,
    isParaRace,
    isRelayRace,
    status,
    requirements,
  } = useEvents();
  const { t } = useTranslation("middleModale");
  const { addUpdateRequest } = useUpdates();


  const form = {
    id,
    startDate,
    endDate,
    name,
    location,
    description,
    link,
    type,
    isKidRace,
    isParaRace,
    isPinned,
    isRelayRace,
    status,
    requirements,
  }

  return (
    <Wrapper>
      <EventForm form={form} onSubmit={addUpdateRequest} isUpdateRequest={true} />
      {
        errors.firestore && (
          <ErrorForm
            error={
              firestoreErrors[errors.firestore] ||
              t("somethingWentWrong")
            }
          />
        )
      }
      <Actions>
      </Actions>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

export default UpdateEvent;
