import "./firebase-config";
import { lazy, useEffect } from "react";
import { Route, Routes } from "react-router";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import ProtectedRoute from "./libs/reuse/components/ProtectedRoute";
import { ADMIN, ANONYMOUS, RACER } from "./constants/roles";
import Home from "./page/Home/Home";
import EventsPage from "./page/Events/Events";
import AddEventPage from "./page/AddEvent/AddEventPage";
import MyEvents from "./page/MyEvents/MyEvents";
import Login from "./page/Auth/Login";
import Register from "./page/Auth/Register";
import ForgotPassword from "./page/Auth/ForgotPassword";
import LazyLoad from "./libs/reuse/components/LazyLoad";
import NotFound from "./page/NotFound/NotFound";
import Layout from "./libs/reuse/components/Layout";
import { ErrorBoundary } from "react-error-boundary";
import AppErrorFallback from "./AppErrorFallback";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import KidsPage from "./page/Kids/KidsPage";
import SingleEvent from "./page/Events/SingleEvent";
import { HelmetProvider } from "react-helmet-async";
import { useAuth } from "./providers/AuthProvider";
import Loader from "./libs/reuse/components/Loader";

const Admin = lazy(() => import("./page/Admin/Admin"));
const Profile = lazy(() => import("./page/Profile/Profile"));
const PrivacyPolicy = lazy(() => import("./page/Privacy-Policy/PrivacyPolicy"));
const AboutUs = lazy(() => import("./page/About-Us/AboutUs"));
const ContactUs = lazy(() => import("./page/Contact-Us/ContactUs"));
const Advertise = lazy(() => import("./page/Advertise/Advertise"));
const CookiePolicy = lazy(() => import("./page/Cookie-Policy/CookiePolicy"));
const Disclaimer = lazy(() => import("./page/Disclaimer/Disclaimer"));

function App() {
  const { ready } = useTranslation();
  const { isInitializing } = useAuth();

  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_googleTagManager,
    });
  }, []);

  if (!ready || isInitializing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loader height={250} />
      </div>
    );
  }

  return (
    <ErrorBoundary
      FallbackComponent={AppErrorFallback}
      onReset={() => {
        // Force reload the page on critical errors
        window.location.href = "/";
      }}
    >
      <HelmetProvider>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        >
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/events" element={<EventsPage />} />
              <Route path="/events/:eventId" element={<SingleEvent />} />
              <Route
                path="my-calendar"
                element={
                  <ProtectedRoute role={RACER}>
                    <MyEvents />
                  </ProtectedRoute>
                }
              />
              <Route path="/kids" element={<KidsPage />} />
              <Route path="add-event" element={<AddEventPage />} />
              <Route
                path="login"
                element={
                  <ProtectedRoute role={ANONYMOUS}>
                    <Login />
                  </ProtectedRoute>
                }
              />
              <Route
                path="register"
                element={
                  <ProtectedRoute role={ANONYMOUS}>
                    <Register />
                  </ProtectedRoute>
                }
              />
              <Route
                path="forgot-password"
                element={
                  <ProtectedRoute role={ANONYMOUS}>
                    <ForgotPassword />
                  </ProtectedRoute>
                }
              />
              <Route
                path="profile/*"
                element={
                  <ProtectedRoute role={RACER}>
                    <LazyLoad component={Profile} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="about-us"
                element={<LazyLoad component={AboutUs} />}
              />
              <Route
                path="contact"
                element={<LazyLoad component={ContactUs} />}
              />
              <Route
                path="advertise"
                element={<LazyLoad component={Advertise} />}
              />
              <Route
                path="privacy-policy"
                element={<LazyLoad component={PrivacyPolicy} />}
              />
              <Route
                path="cookie-policy"
                element={<LazyLoad component={CookiePolicy} />}
              />
              <Route
                path="disclaimer"
                element={<LazyLoad component={Disclaimer} />}
              />
              <Route
                path="admin/*"
                element={
                  <ProtectedRoute role={ADMIN}>
                    <LazyLoad component={Admin} />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </GoogleReCaptchaProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
}

export default App;
