import { useTranslation } from "react-i18next";
import { useAuth } from "../../providers/AuthProvider";
import { useLocale } from "../../providers/LocaleProvider";
import { useCallback, useEffect, useState } from "react";
import { ORGANIZER, RACER } from "../../constants/roles";
import validator from "validator";
import Input from "../../libs/reuse/components/Input";
import ToggleButton from "../../libs/reuse/components/ToggleButton";
import ErrorForm from "../../libs/reuse/components/ErrorForm";
import ButtonContained from "../../libs/reuse/components/ButtonContained";
import ButtonOutlined from "../../libs/reuse/components/ButtonOutlined";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { Heading2 } from "../../libs/reuse/components/texts/textStyles";
import useDebouncedValidation from "../../libs/reuse/hooks/useDebounce";
import { LIGHT } from "../../constants/themes";

function Register() {
    const { register, error, resetError } = useAuth();
    const { lang } = useLocale();
    const { t } = useTranslation("auth")
    const navigate = useNavigate();

    // Reset error on mount
    useEffect(() => {
        resetError();
    }, []);

    const validateEmail = useCallback((email) => {
        return !validator.isEmail(email) && t("invalidEmail")
    }, [t])
    const email = useDebouncedValidation("", validateEmail)

    const validatePassword = useCallback((password) => {
        const errors = [];
        if (password.length < 8) errors.push(t("atLeast8Characters"));
        if (!/[A-Z]/.test(password)) errors.push(t("upperCase"));
        if (!/[a-z]/.test(password)) errors.push(t("lowerCase"));
        if (!/\d/.test(password)) errors.push(t("number"));
        if (!/[!@#$%^&*()_+=[\]{}|\\:;'",.<>/?~`]/.test(password)) {
            errors.push(t("specialChar"));
        }

        return errors.length ? `${t("missing")}: ${errors.join(", ")}` : "";
    }, [t])
    const password = useDebouncedValidation("", validatePassword)

    const validateRePassword = useCallback((rePassword) => {
        return rePassword && password.input && password.input !== rePassword && t("passwordMatch")
    }, [password, t])
    const rePassword = useDebouncedValidation("", validateRePassword)

    const validateFirstName = useCallback((firstName) => {
        return validator.isEmpty(firstName) && t("emptyFirstName")
    }, [t])
    const firstName = useDebouncedValidation("", validateFirstName)

    const validateLastName = useCallback((lastName) => {
        return validator.isEmpty(lastName) && t("emptyLastName")
    }, [t])
    const lastName = useDebouncedValidation("", validateLastName)

    const [role, setRole] = useState(RACER);

    const registerHandler = (e) => {
        e.preventDefault();
        if (email.error || password.error || rePassword.error || firstName.useEffect || lastName.error) {
            return
        }

        const user = {
            email: email.input,
            password: password.input,
            firstName: firstName.input,
            lastName: lastName.input,
            role: role,
            lang: lang,
            photoURL: "",
            theme: LIGHT,
            events: [],
        }

        register(user);
    };

    return (
        <Wrapper>
            <Header>
                <Heading2>{t("signUp")}</Heading2>
            </Header>
            <Content onSubmit={registerHandler}>
                <Input
                    text={t("email")}
                    type="email"
                    autoComplete={"email"}
                    {...email}
                />
                <Input
                    text={t("password")}
                    type="password"
                    autoComplete={"new-password"}
                    showEye={true}
                    {...password}
                />
                <Input
                    text={t("rePassword")}
                    type="password"
                    autoComplete={"new-password"}
                    showEye={true}
                    {...rePassword}
                />
                <Input
                    text={t("firstName")}
                    type="text"
                    autoComplete={"first-name"}
                    {...firstName}
                />
                <Input
                    text={t("lastName")}
                    type="text"
                    autoComplete={"last-name"}
                    {...lastName}
                />
                <Roles>
                    <ToggleButton
                        text={t("racer")}
                        onClick={() => setRole(RACER)}
                        color="#717579"
                        isSelected={role === RACER}
                    />
                    <ToggleButton
                        text={t("organizer")}
                        onClick={() => setRole(ORGANIZER)}
                        color="#717579"
                        isSelected={role === ORGANIZER}
                    />
                </Roles>
                {error && (
                    <ErrorForm
                        error={
                            t(error, { ns: "errors" }) || t("somethingWentWrong")
                        }
                    />
                )}
                <SubmitButton>
                    <ButtonContained
                        text={t("signUp")}
                        color="#6689A1"
                        type="submit"
                    />
                </SubmitButton>
            </Content>
            <Line />
            <span>{t("or")}</span>
            <ButtonOutlined
                text={t("signIn")}
                color="#6689A1"
                onClick={() => navigate("/login")}
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    padding: 30px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    box-sizing: border-box;
    color: #303134;
  `;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 960px) {
    display: none;
  }
`;

const Content = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 500px;

    @media (max-width: 760px) {
        width: 100%;
    }

  `;

const Roles = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
`;

const Line = styled.div`
  width: 50%;
  height: 1px;
  background-color: #d7d7d7;
`;

const SubmitButton = styled.div`
    align-self: center;
    `;

export default Register;
