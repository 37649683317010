import React from "react";
import styled from "styled-components";
import Day from "./components/Day";
import Icon from "../../libs/reuse/components/Icon";
import ButtonOutlined from "../../libs/reuse/components/ButtonOutlined";
import ButtonContained from "../../libs/reuse/components/ButtonContained";
import { useLocale } from "../../providers/LocaleProvider";
import { format, getYear } from "date-fns";
import CalendarBanner from "../banners/CalendarBanner";
import run from "../../assets/images/run.svg";
import { useMediaQuery } from "react-responsive";
import HorizontalTabs from "../../libs/reuse/components/HorizontalTabs";
import { EVENT_TYPES } from "../../constants/events";
import { useTranslation } from "react-i18next";
import { Heading2, Heading4 } from "../../libs/reuse/components/texts/textStyles";

function Calendar({ calendarProps }) {
  const { timeLang } = useLocale();
  const is760 = useMediaQuery({ maxWidth: 760 });
  const { t } = useTranslation("calendar");

  const {
    date,
    weekArray,
    weeks,
    filterType,
    setFilterType,
    filteredEvents,
    prevMonth,
    nextMonth,
    setToday,
    addEvent,
    getTodayEvents,
    dayIsReserved
  } = calendarProps;

  return (
    <Wrapper>
      <Header>
        <Section>
          <HorizontalTabs tabs={
            [
              { name: t("all"), value: "" },
              { icon: "swim", value: EVENT_TYPES.SWIM },
              { icon: "bike", value: EVENT_TYPES.BIKE },
              { icon: "run", value: EVENT_TYPES.RUN },
              { icon: "duathlon", value: EVENT_TYPES.DUATHLON },
              { icon: "triathlon", value: EVENT_TYPES.TRIATHLON }
            ]
          }
            selected={filterType}
            setSelected={setFilterType}
          />
        </Section>
        <Section>
          <Filter>
            <ArrowButton onClick={prevMonth}>
              <Icon name="arrow-month" transform="rotate(90deg)" size="27px" />
            </ArrowButton>
            <Date>
              <span>{format(date, "MMMM", { locale: timeLang })}</span>
              <span>{getYear(date)}</span>
            </Date>
            <ArrowButton onClick={nextMonth}>
              <Icon name="arrow-month" transform="rotate(-90deg)" size="27px" />
            </ArrowButton>
          </Filter>
          <ButtonOutlined text={t("today")} color="#717579" onClick={setToday} />
          <ButtonContained
            text={t("newEvent")}
            color="#6689A1"
            onClick={addEvent}
          />
        </Section>
      </Header>
      <Content>
        <Weekdays>
          {weekArray?.map((day) => (
            <Weekday key={format(day, "iiii")}>
              {is760
                ? format(day, "iii", { locale: timeLang })
                : format(day, "iiii", { locale: timeLang })}
            </Weekday>
          ))}
        </Weekdays>
        {weeks?.map((week) => (
          <Week key={week}>
            {week.map((day) => (
              <Day key={day} day={day} date={date} events={getTodayEvents(day, filteredEvents)} isReserved={dayIsReserved(day)} />
            ))}
          </Week>
        ))}
      </Content>
      <CalendarBanner />
      <img src={run} alt="" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 20px;
  padding: 30px 15px;

  @media (max-width: 480px) {
    padding: 15px 6px;
  }

  img {
    align-self: center;
    width: 650px;
    max-width: 650px;
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  gap: 26px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const Section = styled.div`
  display: flex;
  gap: 26px;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 1600px) {
    gap: 10px;
  }

  @media (max-width: 760px) {
    flex-wrap: wrap;
  }
`;

const Filter = styled.div`
justify-self:start;
  display: flex;
  align-items: center;
  fill: #6689A1;
  flex-grow: 1;
  user-select: none;
`;

const ArrowButton = styled.div`
  cursor: pointer;
`;

const Date = styled(Heading2)`
  text-transform: uppercase;
  display: flex;
  gap: 10px;
  color: #303134;
`;

const Content = styled.div`
`;

const Weekdays = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Weekday = styled(Heading4)`
  width: 100%;
  padding: 10px;
  text-align: center;
  color: #717579;

  @media (max-width: 1600px) {
    font-size: 14px;
  }
`;

const Week = styled.div`
  display: flex;
  justify-content: space-between;
  height: 125px;

  &:nth-last-child(1) {
    margin-bottom: -5px;
  }

  @media (max-width: 960px) {
    height: 100px;
  }

  @media (max-width: 480px) {
    height: 60px;
  }
`;

export default Calendar;
