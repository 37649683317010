import React, { useRef, useState } from "react";
import styled from "styled-components";
import Notifications from "./components/Notifications";
import Messages from "./components/Messages";
import Language from "./components/Language";
import Profile from "./components/Profile";
import SearchInput from "../../libs/reuse/components/SearchInput";
import { useAuth } from "../../providers/AuthProvider";
import ButtonContained from "../../libs/reuse/components/ButtonContained";
import { createSearchParams, useNavigate } from "react-router";
import { ReactComponent as SwimIcon } from "../../assets/images/swim.svg";
import { useTranslation } from "react-i18next";
import MobileHeader from "./components/MobileHeader";
import HeaderModale from "../../features/header-modale/HeaderModale";
import { useModals } from "../../providers/ModalsProvider";

function Header() {
  const { headerModal } = useModals();
  const { user } = useAuth();
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const inputRef = useRef();
  const { t } = useTranslation("header");

  // Function to handle search navigation
  const handleSearch = () => {
    if (searchInput.trim()) {
      // Navigate to events page with search query parameter
      navigate({
        pathname: "/events",
        search: createSearchParams({
          search: searchInput.trim()
        }).toString()
      });
      setSearchInput("");
      if (inputRef.current) inputRef.current.blur();
    }
  };

  return (
    <Wrapper>
      {headerModal && <HeaderModale />}
      <Menu>
        <ImageSwim>
          <SwimIcon width={350} />
        </ImageSwim>
        <MobileHeader />
        <Content>
          <SearchInput
            defaultText={t("search")}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onClick={handleSearch}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            ref={inputRef}
          />
          {user ? (
            <>
              {/* <Notifications newNotifications="12" />
            <Messages newMessages="!" /> */}
              <Profile />
            </>
          ) : (
            <ButtonContained
              text={t("raceIn")}
              color="#6689A1"
              onClick={() => navigate("/login")}
            />
          )}
          <Language />
        </Content>
      </Menu>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 120px;
  display: flex;
  flex-direction: column;
  position: relative;
  user-select: none;

  @media (max-width: 480px) {
    height: auto;
  }
`;

const Menu = styled.div`
  border-bottom: 1px solid #eeeeee;
  border-radius: 2px;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  padding: 0px 50px 0px 20px;
  height: 100%;

  @media (max-width: 960px) {
    padding: 0px 15px;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 25px;
  height: 100%;
  flex-grow: 1;
  position: relative;

  @media (max-width: 960px) {
    display: none;
  }
`;

const ImageSwim = styled.div`
  position: absolute;
  padding: 31px 0px 0px 10px;
  left: 10px;
  z-index: -1;

  @media (max-width: 960px) {
    left: 100px;
  }

  @media (max-width: 780px) {
    left: 0px;
  }

  @media (max-width: 480px) {
    display: none;
  }
`;

export default Header;
