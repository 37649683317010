import React from "react";
import styled from "styled-components";
import Icon from "../../../libs/reuse/components/Icon";
import { Body1 } from "../../../libs/reuse/components/texts/textStyles";

function Messages({ newMessages }) {
  return (
    <Wrapper>
      <Icon name="email" size="28px" color="#717579" />
      <New>{newMessages}</New>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const New = styled(Body1)`
  position: absolute;
  width: 30px;
  height: 30px;
  top: -22px;
  right: -19px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Secondary */
  background: #ED6A5A;
  /* Background */
  border: 4px solid #fbfbfb;
  border-radius: 50%;

  color: #fbfbfb;
`;

export default Messages;
