import React, { createRef, useEffect, useState } from "react";
import styled from "styled-components";
import Icon from "../../../libs/reuse/components/Icon";
import { useModals } from "../../../providers/ModalsProvider";
import {
  ADD_EVENT_MODAL,
  DAILY_EVENTS_MODAL,
  EVENT_DETAILS_MODAL,
} from "../../../constants/modals";
import { format } from "date-fns";
import { useAuth } from "../../../providers/AuthProvider";
import EventsSummary from "./EventsSummary";
import { EVENT_STATUS } from "../../../constants/events";
import { useNavigate } from "react-router";
import { Heading2, Subtitle } from "../../../libs/reuse/components/texts/textStyles";

function Day({ day, date, events, isReserved }) {
  const { openCalendarModal } = useModals();
  const { user } = useAuth();
  const ref = createRef();
  const navigate = useNavigate();

  const [viewMore, setViewMore] = useState(false);
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1920);

  const isOtherMonth = format(day, "MMMM") !== format(date, "MMMM");
  const isToday =
    format(day, "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd");

  useEffect(() => {
    function handleResize() {
      setIsSmallScreen(window.innerWidth <= 1920);

      if (
        ref &&
        (ref?.current?.clientWidth < ref?.current?.scrollWidth ||
          ref?.current?.clientHeight < ref?.current?.scrollHeight)
      ) {
        setViewMore(true);
      } else {
        setViewMore(false);
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize(); // Check on initial render

    return () => window.removeEventListener("resize", handleResize);
  }, [ref]);

  return (
    <Wrapper
      onMouseOver={() => setShowAddEvent(true)}
      onMouseLeave={() => setShowAddEvent(false)}
      onClick={() => openCalendarModal(DAILY_EVENTS_MODAL, { day })}
    >
      <Box
        ref={ref}
        className={`${isToday ? "today" : isReserved ? "reserved" : ""} 
        ${isOtherMonth && "other-month"}`}
      >
        <Header>
          <Number>{format(day, "dd")}</Number>
          {showAddEvent && (
            <CircleWrapper>
              <Circle
                onClick={(e) => {
                  e.stopPropagation();
                  user ? openCalendarModal(ADD_EVENT_MODAL, { day }) : navigate("/add-event");
                }}
              >
                <Icon name="plus" size="15px" />
              </Circle>
            </CircleWrapper>
          )}
        </Header>
        <Events ref={ref} $hide={viewMore || isSmallScreen}>
          {events.map((event) => {
            return (
              <Event
                key={event.id}
                onClick={(e) => {
                  e.stopPropagation();
                  openCalendarModal(EVENT_DETAILS_MODAL, { event });
                }}
                $isCancelled={event.status === EVENT_STATUS.CANCELLED}
              >
                {event.name}
              </Event>
            );
          })}
        </Events>
        {events.length !== 0 && (isSmallScreen || viewMore) && (
          <EventsSummary
            onClick={() => openCalendarModal(DAILY_EVENTS_MODAL, { day })}
            number={events.length}
          />
        )}
        {events.length !== 0 && <Dot />}
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-right: 2px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  width: 100%;
  position: relative;

  &:nth-last-of-type(1) {
    border-right: none;
  }
`;

const Box = styled(Heading2)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 100%;
  transition: 0.4s;
  overflow: hidden;
  border: 4px solid transparent;
  color: #303134;
  background-color: white;
  cursor: pointer;
  gap: 10px;

  &:hover {
    background-color: #E6ECEF;
  }

  &.other-month {
    background-color: #f6f6f6;
    color: #717579;
  }

  &.today {
    background-color: #E6ECEF;
    border: 4px solid #6689A1;
    color: #6689A1;
    border-radius: 4px;

    &:hover {
      background-color: #E6ECEF;
      border-radius: 4px;
    }
  }

  &.reserved {
    background-color: #FEEBD7;

    &:hover {
      background-color: #E6ECEF;
      border-radius: 4px;
    }
  }

  @media (max-width: 760px) {
    padding: 2px;
  }

  @media (max-width: 480px) {
    align-items: center;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Number = styled.div`
  font-size: 24px;

  @media (max-width: 760px) {
    font-size: 16px;
  }
`;

const CircleWrapper = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 760px) {
    display: none;
  }
`;

const Circle = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #6689A1;
  border-radius: 50%;
  color: #fbfbfb;
  fill: #fbfbfb;
  transition: 0.5s;

  &:hover {
    border-radius: 2px solid white;
    filter: saturate(0.8);
    width: 25px;
    height: 25px;
  }
`;

const Events = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  visibility: ${({ $hide }) => ($hide ? "hidden" : "visible")};

  @media (max-width: 480px) {
    display: none;
  }
`;

const Dot = styled.div`
  display: none;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: #6689A1;

  @media (max-width: 480px) {
    display: flex;
  }
`;

const Event = styled(Subtitle)`
  border-left: 3px solid ${({ $isCancelled }) => $isCancelled ? "#ED6A5A" : "#6689A1"};
  padding-left: 3px;
  transition: 0.4s;
  overflow-wrap: anywhere;

  &:hover {
    background-color: #fbfbfb;
    border-radius: 0 5px 5px 0;
  }
`;

export default Day;
