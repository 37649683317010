import "firebase/firestore";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  or,
  and,
  documentId,
  updateDoc,
  doc,
  deleteDoc,
  Timestamp,
  orderBy,
  limit,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../firebase-config";

export const addBanner = async (banner) => {
  await addDoc(collection(db, "banners"), banner);
};

export const getAllBanners = async () => {
  return await getDocs(collection(db, "banners"));
};

// export const getAllEventsQuery = () => {
//   const q = query(collection(db, "events"));
//   return q;
// };

// export const getAllEventsPerMonth = async (startDate, endDate) => {
//   const q = query(
//     collection(db, "events"),
//     and(
//       or(
//         where("startDate", ">=", startDate),
//         where("endDate", ">=", startDate)
//       ),
//       or(where("endDate", "<=", endDate), where("endDate", "<=", endDate))
//     )
//   );
//   return await getDocs(q);
// };

// export const getAllEventsPerMonthQuery = (startDate, endDate) => {
//   const q = query(
//     collection(db, "events"),
//     and(
//       or(
//         where("startDate", ">=", startDate),
//         where("endDate", ">=", startDate)
//       ),
//       or(where("endDate", "<=", endDate), where("endDate", "<=", endDate))
//     )
//   );
//   return q;
// };

// export const getAllEventsPerDay = async (date) => {
//   const q = query(
//     collection(db, "events"),
//     and(where("startDate", "<=", date), where("endDate", ">=", date))
//   );
//   return await getDocs(q);
// };

// export const getAllEventsPerDateQuery = (date) => {
//   const q = query(
//     collection(db, "events"),
//     and(where("startDate", "<=", date), where("endDate", ">=", date))
//   );
//   return q;
// };

// export const getUpcommingEvents = async (numberOfEvents) => {
//   const q = query(
//     collection(db, "events"),
//     where("startDate", ">=", Timestamp.now()),
//     orderBy("startDate"),
//     limit(numberOfEvents || 5)
//   );
//   return await getDocs(q);
// };

// export const getAllPinnedEvents = async () => {
//   const q = query(
//     collection(db, "events"),
//     where("isPinned", "===", true),
//     orderBy("startDate")
//   );
//   return await getDocs(q);
// };

export const getBannerById = async (id) => {
  return await getDoc(doc(db, "assignedBanners", id));
};

export const updateBannerById = async (id, banner) => {
  try {
    await updateDoc(doc(db, "assignedBanners", id), banner);
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};

export const deleteBannerById = async (id) => {
  try {
    await deleteDoc(doc(db, "banners", id));
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};
