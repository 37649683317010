import React, { useState } from "react";
import styled from "styled-components";
import { BG, EN, RO } from "../../../constants/lang";
import { useAuth } from "../../../providers/AuthProvider";
import ButtonOutlined from "../../../libs/reuse/components/ButtonOutlined";
import ButtonContained from "../../../libs/reuse/components/ButtonContained";
import { useLocale } from "../../../providers/LocaleProvider";
import { updateUser } from "../../../features/users/api/firebase";
import { AnimatePresence, motion } from "framer-motion";

function Language() {
  const { lang, updateLang } = useLocale();
  const [isOpen, setIsOpen] = useState(false);

  const langs = [
    { name: "EN", value: EN },
    { name: "BG", value: BG },
  ];
  const { user, id, updateUserDetails } = useAuth();

  const changeLanguage = (lang) => {
    updateLang(lang);

    if (user) {
      updateUser(id, { ...user, lang })
        .then(() => {
          updateUserDetails();
        })
        .catch((err) => console.log(err));
    }
    setIsOpen(false);
  };

  return (
    <Wrapper>
      <DropdownContainer>
        <ButtonOutlined
          text={lang.toUpperCase()}
          color="#6689A1"
          onClick={() => setIsOpen(!isOpen)} />

        <AnimatePresence>
          {isOpen && (
            <DropdownList
              initial={{ opacity: 0, scale: 0.95, y: -5 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: -5 }}
              transition={{ duration: 0.2, ease: "easeOut" }}
            >
              {langs.map((option) => (
                <ButtonContained
                  key={option.value}
                  text={option.name}
                  color={option.value === lang ? "#303134" : "#6689A1"}
                  onClick={() => {
                    changeLanguage(option.value);
                  }}
                />
              ))}
            </DropdownList>
          )}
        </AnimatePresence>
      </DropdownContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

// Styled Components
const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;


const DropdownList = styled(motion.div)`
  position: absolute;
  margin-top: 8px;
  border-radius: 14px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1px;
`;


export default Language;
