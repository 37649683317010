import React from 'react'
import { useNotification } from '../../../providers/NotificationProvider';
import styled from 'styled-components';
import Icon from '../../../libs/reuse/components/Icon';
import { useTranslation } from 'react-i18next';

function Copy({ id }) {
    const { t } = useTranslation("event");
    const { setNotification } = useNotification();

    return (
        <CopyButton
            onClick={(e) => {
                e.stopPropagation();
                const eventUrl = `${window.location.origin}/events/${id}`;
                navigator.clipboard.writeText(eventUrl)
                    .then(() => {
                        // Show a temporary success message
                        setNotification({
                            success: t("linkCopied"),
                        });
                    })
                    .catch(err => {
                        console.error('Could not copy text: ', err);
                        setNotification({
                            error: t("copyFailed"),
                        });
                    });
            }}
        >
            <Icon name="copy" size="20px" />
        </CopyButton>
    )
}

const CopyButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6689A1;
  fill: #6689A1;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
`;

export default Copy