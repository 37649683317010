import React from "react";
import styled, { css } from "styled-components";
import Icon from "./Icon";
import { Heading4 } from "./texts/textStyles";

function ButtonOutlined({ iconName, iconSize, text, color, onClick, width }) {
  return (
    <Wrapper color={color} onClick={onClick} width={width}>
      {iconName ? <Icon name={iconName} size={iconSize} /> : <></>}
      <Heading4>{text}</Heading4>
    </Wrapper>
  );
}

const Wrapper = styled.button`
  font: inherit;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  gap: 10px;
  border: none;
  border-radius: 14px;
  padding: 12px 25px;
  ${({ width }) => width && `width: ${width};`}
  ${({ color }) =>
    color &&
    css`
      color: ${color};
      fill: ${color};
      border: 1px solid ${color};
      opacity: 0.8;
    `}

  &:hover {
    opacity: 1;
  }
`;

export default ButtonOutlined;
