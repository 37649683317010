import React from "react";
import styled from "styled-components";
import Button from "../../../libs/reuse/components/Button";
import ButtonContained from "../../../libs/reuse/components/ButtonContained";
import { useAuth } from "../../../providers/AuthProvider";
import { useModals } from "../../../providers/ModalsProvider";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Heading2 } from "../../../libs/reuse/components/texts/textStyles";

function Settings() {
  const { logout } = useAuth();
  const { closeHeaderModal } = useModals();
  const navigate = useNavigate();
  const { t } = useTranslation("headerModale");

  const settingsHandler = () => {
    navigate("/profile");
    closeHeaderModal();
  };

  return (
    <Wrapper onClick={(e) => e.stopPropagation()}>
      <Header>
        <Content>
          <Title>{t("userSettings")}</Title>
        </Content>
        <Button
          iconName="close"
          color="#ED6A5A"
          onClick={() => closeHeaderModal()}
          padding={"12px 6px"}
        ></Button>
      </Header>
      <Options>
        <Button
          text={t("profile")}
          iconName="profile"
          color="#303134"
          padding={"12px 6px"}
          onClick={settingsHandler}
        />
      </Options>
      <ButtonContained
        text={t("logout")}
        color="#6689A1"
        onClick={(e) => {
          e.preventDefault();
          logout();
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  z-index: 2;
  position: absolute;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.52);
  border-radius: 0px 0px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 340px;
  box-sizing: border-box;
  top: 0px;
  right: 50px;

  @media (max-width: 1600px) {
    right: 35px;
  }

  @media (max-width: 1200px) {
    right: 20px;
  }

  @media (max-width: 960px) {
    width: 100%;
    right: 0px;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 960px) {
    display: none;
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items:start;
  justify-content: start;
  width: 90%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
`;

const Title = styled(Heading2)`
  /* Primary Text */
  color: #303134;
`;

export default Settings;
