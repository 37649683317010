import { useEffect, useState } from "react";
import { useCalendar } from "../../../providers/CalendarProvider";
import { useAuth } from "../../../providers/AuthProvider";
import { format, isAfter } from "date-fns";
import { EVENT_STATUS } from "../../../constants/events";

export default function useSchedule() {
  const { events, date } = useCalendar();
  const { user } = useAuth();

  const [pinnedEvent, setPinnedEvents] = useState([]);
  const [pinnedDates, setPinnedDates] = useState([]);
  const [upcommingEvents, setUpcommingEvents] = useState([]);
  const [upcommingDates, setUpcommingDates] = useState([]);
  const [myEvents, setMyEvents] = useState([]);
  const [myEventsDates, setMyEventsDates] = useState([]);

  useEffect(() => {
    let eventsArr = events
      .filter((event) => {
        return (
          isAfter(
            format(event.startDate, "yyyy-MM-dd"),
            format(new Date(), "yyyy-MM-dd")
          ) &&
          !event.isPinned &&
          event.status === EVENT_STATUS.APPROVED
        );
      })
      .sort((a, b) => a.startDate - b.startDate)
      .slice(0, 5);

    let datesSet = new Set();
    eventsArr.map((event) =>
      datesSet.add(format(event.startDate, "yyyy-MM-dd"))
    );

    setUpcommingDates(datesSet);
    setUpcommingEvents(eventsArr);
  }, [events]);

  useEffect(() => {
    let eventsArr = events
      .filter((event) => {
        return (
          isAfter(
            format(event.startDate, "yyyy-MM-dd"),
            format(new Date(), "yyyy-MM-dd")
          ) &&
          event.isPinned &&
          event.status === EVENT_STATUS.APPROVED
        );
      })
      .sort((a, b) => a.startDate - b.startDate);

    let datesSet = new Set();
    eventsArr.map((event) =>
      datesSet.add(format(event.startDate, "yyyy-MM-dd"))
    );

    setPinnedDates(datesSet);
    setPinnedEvents(eventsArr);
  }, [events]);

  useEffect(() => {
    let myEvents = events
      .filter(
        (event) =>
          user?.events?.[event.id] &&
          (format(date, "yyyy-MM") === format(event.startDate, "yyyy-MM") ||
            format(date, "yyyy-MM") === format(event.endDate, "yyyy-MM")) &&
          event.status === EVENT_STATUS.APPROVED
      )
      .sort((a, b) => a.startDate - b.startDate);
    setMyEvents(myEvents);
    let datesSet = new Set();
    myEvents.map((event) =>
      datesSet.add(format(event.startDate, "yyyy-MM-dd"))
    );
    setMyEventsDates(datesSet);
  }, [user, events, date]);

  return {
    upcommingEvents,
    upcommingDates,
    pinnedEvent,
    pinnedDates,
    myEvents,
    myEventsDates,
  };
}
