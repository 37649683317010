import React from "react";
import styled from "styled-components";
import { useModals } from "../../../providers/ModalsProvider";
import EventCard from "../../event/EventCard";

function EventDetails() {
  const { calendarModal } = useModals();
  const event = calendarModal.data.event;

  return (
    <Wrapper>
      <EventCard key={event.id} event={event} />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default EventDetails;
