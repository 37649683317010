import { createContext, useContext, useState } from "react";

const ModalsContext = createContext();

const ModalsProvider = ({ children }) => {
  const [headerModal, setHeaderModal] = useState();
  const [modalStack, setModalStack] = useState([]);

  const openHeaderModal = (modal) => {
    setHeaderModal(modal);
  };

  const closeHeaderModal = () => {
    setHeaderModal();
  };

  // Push a new modal onto the stack
  const openCalendarModal = (modal, data = {}) => {
    setModalStack((prevStack) => [...prevStack, { modal, data }]);
  };

  // Close the top modal and return to previous if exists
  const closeCalendarModal = () => {
    setModalStack((prevStack) => {
      if (prevStack.length <= 1) {
        // If this is the last/only modal, clear the stack
        return [];
      } else {
        // Otherwise, remove the top modal to reveal previous one
        return prevStack.slice(0, -1);
      }
    });
  };

  // Explicitly close all modals
  const closeAllCalendarModals = () => {
    setModalStack([]);
  };

  // Get the current (top) modal from the stack
  const calendarModal =
    modalStack.length > 0 ? modalStack[modalStack.length - 1] : null;

  return (
    <ModalsContext.Provider
      value={{
        headerModal,
        calendarModal,
        openHeaderModal,
        closeHeaderModal,
        openCalendarModal,
        closeCalendarModal,
        closeAllCalendarModals,
        modalStackLength: modalStack.length, // Expose stack length for components to use
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
};

export default ModalsProvider;

export const useModals = () => {
  return useContext(ModalsContext);
};
