import { useState } from "react";
import { useModals } from "../../../providers/ModalsProvider";
import { useAuth } from "../../../providers/AuthProvider";
import {
  addEvent,
  deleteEventById,
  updateEventById,
} from "../../events/api/firebase";
import { useNotification } from "../../../providers/NotificationProvider";
import { EVENT_STATUS } from "../../../constants/events";
import { useTranslation } from "react-i18next";

export function useEvents() {
  const modals = useModals() || {}; // Ensure modals is always an object
  const { calendarModal = {}, closeCalendarModal } = modals;
  const event = calendarModal.data.event || {}; // Safe fallback
  const day = calendarModal.data.day || new Date();
  const { id: uid } = useAuth();
  const { setNotification } = useNotification();
  const { t } = useTranslation("events");

  const [id] = useState(event?.id);
  const [startDate, setStartDate] = useState(
    event?.startDate || day || new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [endDate, setEndDate] = useState(
    event?.endDate || day || new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [name, setName] = useState(event?.name || "");
  const [location, setLocation] = useState(
    event?.location || { name: "", lat: "", lng: "" }
  );
  const [description, setDescription] = useState(event?.description || "");
  const [link, setLink] = useState(event?.link || "");
  const [type, setType] = useState(event?.type || "");
  const [isPinned, setIsPinned] = useState(event?.isPinned || false);
  const [status] = useState(event?.status || EVENT_STATUS.APPROVAL_PENDING);
  const [isKidRace, setIsKidRace] = useState(event?.isKidRace || false);
  const [isParaRace, setIsParaRace] = useState(event?.isParaRace || false);
  const [isRelayRace, setIsRelayRace] = useState(event?.isRelayRace || false);

  const [requirements] = useState(event?.requirements || []);

  const [errors, setErrors] = useState({
    name: "",
    location: "",
    description: "",
    link: "",
    type: "",
    firestore: "",
  });

  const addNewEvent = (form) => {
    const event = {
      ...form,
      addedBy: uid,
      status: EVENT_STATUS.APPROVAL_PENDING,
    };

    addEvent(event)
      .then(() => {
        closeCalendarModal();
        setNotification({
          success: t("eventEntered"),
        });
      })
      .catch((err) => {
        setErrors((prev) => ({ ...prev, firestore: err.code }));
        console.log(err);
      });
  };

  const updateEvent = (form) => {
    updateEventById(id, { ...form })
      .then(() => {
        closeCalendarModal();
        setNotification({
          success: t("eventEdited"),
        });
      })
      .catch((err) => {
        setErrors((prev) => ({ ...prev, firestore: err.code }));
        console.log(err);
      });
  };

  const deleteEvent = () => {
    deleteEventById(id)
      .then(() => {
        closeCalendarModal();
        setNotification({
          success: t("eventDeleted"),
        });
      })
      .catch((err) => {
        setErrors((prev) => ({ ...prev, firestore: err.code }));
        console.log(err);
      });
  };

  const cancelEvent = () => {
    updateEventById(id, { status: EVENT_STATUS.CANCELLED })
      .then(() => {
        closeCalendarModal();
        setNotification({
          success: t("eventCancelled"),
        });
      })
      .catch((err) => {
        setErrors((prev) => ({ ...prev, firestore: err.code }));
        console.log(err);
      });
  };

  const approveEvent = () => {
    updateEventById(id, { status: EVENT_STATUS.APPROVED })
      .then(() => {
        closeCalendarModal();
        setNotification({
          success: t("eventApproved"),
        });
      })
      .catch((err) => {
        setErrors((prev) => ({ ...prev, firestore: err.code }));
        console.log(err);
      });
  };

  return {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    name,
    setName,
    location,
    setLocation,
    description,
    setDescription,
    link,
    setLink,
    type,
    setType,
    isPinned,
    setIsPinned,
    isKidRace,
    setIsKidRace,
    isParaRace,
    setIsParaRace,
    isRelayRace,
    setIsRelayRace,
    status,
    requirements,
    errors,
    id,
    setErrors,
    addNewEvent,
    updateEvent,
    deleteEvent,
    cancelEvent,
    approveEvent,
  };
}
