import { useState, useEffect } from "react";

// Debounce Hook
const useDebounce = (value, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
};

// Validation Hook
export default function useDebouncedValidation(
  initialValue,
  validateFn = () => {
    return true;
  },
  delay = 500
) {
  const [input, setInput] = useState(initialValue);
  const [error, setError] = useState("");
  const [isTouched, setIsTouched] = useState(false);
  const debouncedInput = useDebounce(input, delay);

  useEffect(() => {
    if (isTouched) {
      setError(validateFn(debouncedInput));
    }
  }, [debouncedInput, validateFn]);

  const handleChange = (value) => {
    setInput(value);
    setIsTouched(true);
  };

  return {
    input,
    setInput,
    onChange: handleChange,
    setError,
    error,
  };
}
