import * as Sentry from "@sentry/react";

// Get the current environment
const environment = process.env.NODE_ENV || "development";

Sentry.init({
  dsn: "https://da883c3eeafb63b9c34fc4aff3237aa2@o4508966883688448.ingest.de.sentry.io/4508966885785680",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Add environment to distinguish between dev/prod errors
  environment: environment,

  // Disable Sentry in development to avoid CORS issues during local testing
  enabled: environment === "production",

  // Lower the sample rate for development if enabled
  tracesSampleRate: environment === "production" ? 1.0 : 0.1,

  // Update the tracePropagationTargets to include your actual domains
  tracePropagationTargets: [
    "localhost",
    "irace.club",
    /^https:\/\/irace\.club/,
    /^https:\/\/[^/]*\.irace\.club/,
  ],

  // Keep these the same or adjust as needed
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // Add this to provide more detailed debugging if needed
  debug: environment !== "production",

  // Set allowed domains explicitly
  allowUrls: [
    /^https?:\/\/irace\.club/,
    /^https?:\/\/[^/]*\.irace\.club/,
    // Include your localhost for testing if needed
    /^http:\/\/localhost/,
  ],
});
