export const EVENT_TYPES = {
  SWIM: "swim",
  BIKE: "bike",
  RUN: "run",
  DUATHLON: "duathlon",
  TRIATHLON: "triathlon",
};

export const EVENT_STATUS = {
  APPROVAL_PENDING: "APPROVAL_PENDING",
  APPROVED: "APPROVED",
  CANCELLED: "CANCELLED",
};
