import React, { useState } from 'react'
import styled from 'styled-components';
import Button from '../../../libs/reuse/components/Button';
import { deleteBannerById } from '../api/firebase';
import { useNotification } from '../../../providers/NotificationProvider';
import { Body2 } from '../../../libs/reuse/components/texts/textStyles';
import { useTranslation } from 'react-i18next';

function Banner({ banner, getBanners }) {
    const { setNotification } = useNotification();
    const { t } = useTranslation("banners");

    const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

    const handleImageLoad = (e) => {
        const { naturalHeight, naturalWidth } = e.target;
        setDimensions({ height: naturalHeight, width: naturalWidth });
    };

    const deleteImage = async (e) => {
        e.preventDefault();
        deleteBannerById(banner.id)
            .then(() => {
                setNotification({ success: t("bannerDeleted") })
                getBanners();
            })
            .catch(err => {
                console.log(err)
                setNotification({ error: t("bannerDeletionFailed") })
            }
            )
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(banner.url);
        setNotification({ success: t("bannerCopied") })
    }
    return (
        <Wrapper>
            <Content>
                <Body2>{t("w")} x {t("h")}: {dimensions.width}x{dimensions.height}{t("px")}</Body2>
                <a href={banner?.link}><Image src={banner?.url} srcSet={banner?.url} alt='banner' onLoad={handleImageLoad} /></a>
            </Content>
            {getBanners && <Actions>
                <Button iconName="copy" color="#6689A1" onClick={(e) => copyToClipboard(e)} />
                <Button iconName="delete" color="#ED6A5A" onClick={(e) => deleteImage(e)} />
            </Actions>}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
`

const Content = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;`

const Image = styled.img`
max-height: 100%;
max-width: 100%;
`

const Actions = styled.div`
display: flex;
flex-direction: column;`

export default Banner