import React from 'react'
import { useTranslation } from 'react-i18next';
import { useEventSearch } from '../hooks/useEventSearch';
import styled from 'styled-components';
import Button from '../../../libs/reuse/components/Button';

function EventSearch({ events, setSearchResults }) {
    const { t } = useTranslation("events")

    const { searchQuery, handleSearchReset } = useEventSearch(events, setSearchResults)

    if (!searchQuery || !events) return null;

    return (
        <SearchQueryContainer>
            <SearchQueryText>
                {t("searchResults")}: <strong>"{searchQuery}"</strong>
            </SearchQueryText>
            <Button
                onClick={handleSearchReset}
                color="#ED6A5A"
                iconName={"close"}
                padding={"0 10px"}
                iconSize={"18px"}
            />
        </SearchQueryContainer>
    )
}

const SearchQueryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px 15px;
  border-radius: 8px;
  margin-bottom: 15px;
`;

const SearchQueryText = styled.div`
  font-size: 16px;
  color: #303134;
`;

export default EventSearch