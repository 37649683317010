import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router";

export function useEventSearch(events, setSearchResults) {
  const navigate = useNavigate();

  // Add searchParams hook
  const [searchParams] = useSearchParams();

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    // Get search query from URL
    const searchQuery = searchParams.get("search")?.toLowerCase().trim() || "";
    setSearchQuery(searchQuery);
  }, [searchParams]);

  // Then, apply search filtering on top of the existing filters
  useEffect(() => {
    // Filter events based on search query
    const filtered = events?.filter((event) => {
      // Search in multiple fields
      const nameMatch = event.name?.toLowerCase().includes(searchQuery);
      const locationMatch = event.location?.name
        ?.toLowerCase()
        .includes(searchQuery);
      const descriptionMatch = event.description
        ?.toLowerCase()
        .includes(searchQuery);

      // Add any other fields you want to search in
      return nameMatch || locationMatch || descriptionMatch;
    });

    setSearchResults(filtered);
  }, [events, setSearchResults, searchQuery, searchParams]);

  function handleSearchReset() {
    // Reset search query in URL and state
    setSearchQuery("");
    navigate("/events");
  }

  return {
    searchQuery,
    setSearchQuery,
    handleSearchReset,
  };
}
