import React from 'react'
import Events from '../../features/events/Events';
import styled from 'styled-components';

function KidsPage() {
    return (<>
        <Wrapper>
            <Events kids={true} />
        </Wrapper>
    </>
    );
}

const Wrapper = styled.div`
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 20px;
    `;

export default KidsPage