import React from "react";
import Icon from "../../../libs/reuse/components/Icon";
import { ADMIN } from "../../../constants/roles";
import styled from "styled-components";
import { NavLink } from "react-router";
import { useAuth } from "../../../providers/AuthProvider";
import H5Text from "../../../libs/reuse/components/texts/H5";
import { useModals } from "../../../providers/ModalsProvider";
import { useTranslation } from "react-i18next";


function Navigation() {
  const { user } = useAuth();
  const { closeHeaderModal } = useModals();
  const { t } = useTranslation("header");

  return (
    <Nav>
      <Button to="/" onClick={() => closeHeaderModal()}>
        <Icon name="home" size="24px" />
        <H5Text>{t("home")}</H5Text>
      </Button>
      <Button to="/events" onClick={() => closeHeaderModal()}>
        <Icon name="event" size="24px" />
        <H5Text>{t("events")}</H5Text>
      </Button>
      <Button to="/my-calendar" onClick={() => closeHeaderModal()}>
        <Icon name="calendar" size="24px" />
        <H5Text>{t("myCalendar")}</H5Text>
      </Button>
      <Button to="/kids" onClick={() => closeHeaderModal()}>
        <Icon name="child-reaching" size="24px" />
        <H5Text>{t("forKids")}</H5Text>
      </Button>
      {user?.role === ADMIN && (
        <Button to="/admin" onClick={() => closeHeaderModal()}>
          <Icon name="bars" size="24px" />
          <H5Text>{t("admin")}</H5Text>
        </Button>
      )}
    </Nav>
  );
}

const Nav = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 35px;
  box-sizing: border-box;

  @media (max-width: 1500px) {
    padding: 0px 15px;
  }

  @media (max-width: 960px) {
    padding: 0px;
  }
`;

const Button = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 16px 16px;
  cursor: pointer;
  border-radius: 16px;
  color: #717579;
  fill: #717579;
  text-decoration: none;

  &:hover {
    background-color: #f5f5f5;
  }

  &.active {
    background-color: #E6ECEF;
    color: #6689A1;
    fill: #6689A1;
  }

  @media (max-width: 960px) {
    padding: 8px 16px;
  }
`;

export default Navigation;
