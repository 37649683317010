import React from "react";
import styled from "styled-components";
import { useAuth } from "../../../providers/AuthProvider";
import { SETTINGS_MODAL } from "../../../constants/modals";
import { useModals } from "../../../providers/ModalsProvider";
import { Heading1, Heading4 } from "../../../libs/reuse/components/texts/textStyles";

function Profile() {
  const {
    user: { firstName, lastName, photoURL },
  } = useAuth();

  const { openHeaderModal } = useModals()

  return (
    <Wrapper>
      <PersonalData>
        {(firstName || lastName) && <Name>{firstName} {lastName}</Name>}
      </PersonalData>
      <Picture $url={photoURL} onClick={() => openHeaderModal(SETTINGS_MODAL)}>
        {photoURL ? <img alt="user" srcSet={photoURL} /> : <Initials>{firstName && firstName[0].toUpperCase()}{lastName && lastName[0].toUpperCase()}</Initials>}
      </Picture>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 960px) {
    flex-direction: column-reverse;
    align-items: end;
  }
`;

const PersonalData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;

  @media (max-width: 1200px) {
    display: none;
  }

`;

const Name = styled(Heading4)`
  /* Primary Text */
  color: #303134;
`;

const Picture = styled.div`
  overflow: hidden;
  cursor: pointer;
  border-radius: 16px;
  width: 60px;
  height: 60px;
  background-color: #c4c4c4;
  outline: 2px solid #c4c4c4;

  &:hover{
    outline: 2px solid #6689A1;;
  }

  img {
    border-radius: 16px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Initials = styled(Heading1)`
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  height: 100%;
  background-color: #6689A1;
  color: #ffffff;
`

export default Profile;
