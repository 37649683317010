import { useTranslation } from "react-i18next";
import { UPDATE_STATUS } from "../../../constants/updates";
import { useNotification } from "../../../providers/NotificationProvider";
import { useAuth } from "../../../providers/AuthProvider";
import { useModals } from "../../../providers/ModalsProvider";
import { addUpdate, updateUpdateById } from "../api/firebase";

export function useUpdates() {
  const { setNotification } = useNotification();
  const { closeCalendarModal } = useModals();
  const { t } = useTranslation("updates");
  const { id } = useAuth();

  const addUpdateRequest = (form) => {
    addUpdate({
      ...form,
      status: UPDATE_STATUS.PENDING,
      requestedBy: id,
    })
      .then(() => {
        closeCalendarModal();
        setNotification({
          success: t("eventRequested"),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const approveUpdate = (id) => {
    updateUpdateById(id, { status: UPDATE_STATUS.APPROVED })
      .then(() => {
        setNotification({
          success: t("updateApproved"),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const discardUpdate = (id) => {
    updateUpdateById(id, { status: UPDATE_STATUS.DISCARDED })
      .then(() => {
        setNotification({
          success: t("discardUpdate"),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return { addUpdateRequest, approveUpdate, discardUpdate };
}
