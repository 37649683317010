import React from "react";
import styled from "styled-components";
import Events from "../../features/events/Events";

function MyEvents() {
  return (<>
    <Wrapper>
      <Events myEvents={true} />
    </Wrapper>
  </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
`;


export default MyEvents;
