


import styled from "styled-components";
import { Heading7 } from "../../../libs/reuse/components/texts/textStyles";
import { useTranslation } from "react-i18next";

function Donate() {
  const { t } = useTranslation("sidebar");

  // Get the donation link from environment variables
  const donationLink = process.env.REACT_APP_STRIPE_DONATION_LINK ||
    "https://donate.stripe.com/test_14k8xV0Yq5C22xG9AB"; // Fallback if env var is not set

  const handleDonate = () => {
    // Open in a new tab with target="_blank"
    window.open(donationLink, "_blank", "noopener noreferrer");
  };

  return <DonateButton onClick={handleDonate}><Heading7>🏆 {t("donate")}</Heading7></DonateButton>;
}

const DonateButton = styled.button`
  background:  #6689A1;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    transform: scale(1.03);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: scale(0.98);
  }
`;

export default Donate;