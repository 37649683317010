import React from 'react'
import ButtonContained from '../../../libs/reuse/components/ButtonContained'
import { EVENT_STATUS } from '../../../constants/events'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

function EventActions({ status, approveEvent, cancelEvent, deleteEvent, }) {
    const { t } = useTranslation("events");

    return (
        <Wrapper>
            {status === EVENT_STATUS.CANCELLED && <ButtonContained
                text={t("activate")}
                iconName="tick"
                color="#BBD900"
                onClick={approveEvent}
            />}
            {status !== EVENT_STATUS.CANCELLED && <ButtonContained
                text={t("cancel")}
                iconName="denied"
                color="#ED6A5A"
                onClick={cancelEvent}
            />}
            <ButtonContained
                text={t("delete")}
                iconName="close"
                color="#ED6A5A"
                onClick={deleteEvent}
            />

        </Wrapper>
    )
}

const Wrapper = styled.div`
display: flex;
width: 100%;
gap: 20px;
justify-content: center;

button: {
    flex-grow: 1;
}
`

export default EventActions