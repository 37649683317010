import styled from "styled-components";

const Scroll = ({ children }) => {
  return (
    <ScrollContainer>
      {children}
    </ScrollContainer>
  );
};

const ScrollContainer = styled.div`
  max-height: 100vh;
  overflow-y: auto;
  padding-right: 2px; 
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 10px; /* Space reserved */
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent; /* Initially hidden */
    border-radius: 6px;
  }

  &:hover {
    scrollbar-color: rgba(0, 0, 0, 0.3) transparent; /* Firefox */
  }

  &:hover::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
  }

  &:hover::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.5);
  }
`;

export default Scroll;