import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import DatePicker from '../../../libs/reuse/components/DatePicker';
import HorizontalTabs from '../../../libs/reuse/components/HorizontalTabs';
import Input from '../../../libs/reuse/components/Input';
import { EVENT_TYPES } from '../../../constants/events';
import { useTranslation } from 'react-i18next';
import { useEventFilters } from '../hooks/useEventFilters';

function EventFilters({ showFilter, events, setFilterResults }) {
    const { t } = useTranslation("events");
    const {
        name,
        setName,
        from,
        setFrom,
        to,
        setTo,
        location,
        setLocation,
        description,
        setDescription,
        link,
        setLink,
        filterType,
        setFilterType,
    } = useEventFilters(events, setFilterResults);

    return (
        <AnimatePresence>
            {showFilter && (
                <FilterWrapper
                    as={motion.div}
                    layout
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.5, ease: 'easeInOut' }}
                >
                    <Filters>
                        <Line style={{ justifyContent: 'center' }}>
                            <HorizontalTabs
                                tabs={[
                                    { name: t('all'), value: '' },
                                    { icon: 'swim', value: EVENT_TYPES.SWIM },
                                    { icon: 'bike', value: EVENT_TYPES.BIKE },
                                    { icon: 'run', value: EVENT_TYPES.RUN },
                                    { icon: 'duathlon', value: EVENT_TYPES.DUATHLON },
                                    { icon: 'triathlon', value: EVENT_TYPES.TRIATHLON },
                                ]}
                                selected={filterType}
                                setSelected={setFilterType}
                            />
                        </Line>
                        <Line>
                            <Between>
                                <DatePicker
                                    text={t('from')}
                                    input={from}
                                    onChange={(value) => setFrom(value)}
                                />
                                <DatePicker
                                    text={t('to')}
                                    input={to}
                                    onChange={(value) => setTo(value)}
                                />
                            </Between>
                        </Line>
                        <Line>
                            <Input text={t('name')} input={name} onChange={setName} />
                            <Input
                                text={t('location')}
                                input={location}
                                onChange={setLocation}
                            />
                            <Input
                                text={t('description')}
                                input={description}
                                onChange={setDescription}
                            />
                            <Input text={t('eventLink')} input={link} onChange={setLink} />
                        </Line>
                    </Filters>
                </FilterWrapper>
            )}
        </AnimatePresence>
    );
}

const FilterWrapper = styled.div`
  overflow: hidden;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const Line = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const Between = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export default EventFilters;