import React from 'react'
import { NavLink } from 'react-router'
import styled from 'styled-components'
import { Heading6 } from './texts/textStyles'

function VerticalMenu({ options }) {
  return (
    <Wrapper>
      {options.map((option, i) => (
        <Link key={i} to={option.path} end >
          <Heading6>{option.label}</Heading6>
        </Link>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
margin-top: 60px;
padding: 0px 10px;
display: flex;
flex-direction: column;
gap: 5px;
flex-basis: 25%;
flex-shrink: 0;
border-radius: 16px;
color: #303134;

a {
    text-decoration: none;
    color: #303134; 
}
`

const Link = styled(NavLink)`
padding: 8px 25px;
border-radius: 5px;

  &:hover{
    background-color: #f5f5f5;
  }

  &.active {
    opacity: 1;
    color: #303134;
    background-color: #E6ECEF;
  }
  `

export default VerticalMenu