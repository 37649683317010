import {
  useContext,
  createContext,
  useState,
  useEffect,
  Suspense,
} from "react";
import Notification from "../libs/reuse/components/Notification";
import { requestPermission, messaging } from "../firebase-config";
import { onMessage } from "firebase/messaging";

const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState();
  const [isVisible, setIsVible] = useState(false);

  useEffect(() => {
    requestPermission();
    onMessage(messaging, (payload) => {
      setNotification({
        success: `${payload.notification.title} - ${payload.notification.body}`,
      });
    });
  }, []);

  useEffect(() => {
    if (notification) {
      setIsVible(true);
      const timeId = setTimeout(() => {
        setIsVible(false);
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [notification]);

  useEffect(() => {
    if (!isVisible) {
      const timeId = setTimeout(() => {
        setIsVible(false);
      }, 1000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [isVisible]);

  const resetNotification = () => {
    setIsVible(false);
  };

  return (
    <Suspense fallback={<></>}>
      <NotificationContext.Provider
        value={{
          notification,
          isVisible,
          setNotification,
          resetNotification,
        }}
      >
        {notification && <Notification />}
        {children}
      </NotificationContext.Provider>
    </Suspense>
  );
};

export default NotificationProvider;

export const useNotification = () => {
  return useContext(NotificationContext);
};
