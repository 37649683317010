import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { useMediaQuery } from "react-responsive";
import { Heading7 } from "./texts/textStyles";


function HorizontalTabs({ tabs, selected, setSelected }) {
  const is480 = useMediaQuery({ maxWidth: 480 });

  return (
    <Wrapper>
      {tabs.map((tab) => (
        <ActivityTypeButton
          key={tab.value}
          className={selected === tab.value && "active"}
          onClick={() => setSelected(tab.value)}
        >
          {(tab.icon && <Icon name={tab.icon} size={is480 ? "28px" : "38px"} />) || <Heading7>{tab.name}</Heading7>}
        </ActivityTypeButton>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  background-color: #E6ECEF;
  border-radius: 16px;
  fill: #717579;
  align-self: end;
  justify-content:center;

  @media (max-width: 1200px) {
    align-self: center;
  }
`;

const ActivityTypeButton = styled.button`
  font: inherit;
  background-color: transparent;
  border: none;
  line-height: 24px;
  padding: 12px 20px;
  cursor: pointer;
  transition: 0.5s;
  color: #717579;
  fill: #717579;
  opacity: 0.8;
  font: inherit;

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
    color: #303134;
    background-color: #ffffff;
    box-shadow: 0px 6px 20px rgba(181, 181, 181, 0.42);
    border-radius: 16px;
  }

  @media (max-width: 760px) {
    padding: 10px 12px;
  }

  @media (max-width: 480px) {
    padding: 10px 12px;
  }
`;

export default HorizontalTabs;
