import React from 'react'
import styled from 'styled-components'
import ButtonContained from '../../../libs/reuse/components/ButtonContained'
import { Heading2 } from '../../../libs/reuse/components/texts/textStyles'
import { useTranslation } from 'react-i18next'
import { useCalendar } from '../../../providers/CalendarProvider'

function AddEventBanner() {
    const { t } = useTranslation("addEvent");
    const { addEvent } = useCalendar()

    return (
        <Wrapper>
            <Heading2>{t("addEventBannerText")}</Heading2>
            <ButtonContained
                text={t("newEvent")}
                color="#6689A1"
                onClick={addEvent}
            />
        </Wrapper>
    )
}

const Wrapper = styled.div`
padding: 30px;
display: flex;
gap: 20px;
justify-content: center;
align-items: center;
flex-grow:1;
border-top: 1px solid #e0e0e0;
`

export default AddEventBanner