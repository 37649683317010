import React from "react";
import styled from "styled-components";
import Event from "./components/Event";
import useSchedule from "./hooks/useSchedule";
import MyEvent from "./components/MyEvent";
import { format } from "date-fns";
import { useLocale } from "../../providers/LocaleProvider";
import { Heading3, Heading5 } from "../../libs/reuse/components/texts/textStyles";
import { useTranslation } from "react-i18next";
import cycle from "../../assets/images/cycle.svg";

function Schedule({ title, subtitle, show }) {
  const {
    pinnedDates,
    pinnedEvent,
    upcommingDates,
    upcommingEvents,
    myEventsDates,
    myEvents,
  } = useSchedule();
  const { timeLang } = useLocale();
  const { t } = useTranslation("middleModale");

  if (!show.pinned && !show.upcommingEvents && !show.myEvents) {
    return null;
  }

  const showPinnedEvents = show.pinned && pinnedEvent.length > 0;
  const showUpcommingEvents = show.upcomming && upcommingEvents.length > 0;
  const showMyEvents = show.myEvents && myEvents.length > 0;

  const showEvents = showPinnedEvents || showUpcommingEvents || showMyEvents;

  if (!showEvents) {
    return null
  }

  return (
    <Wrapper>
      <Title>{title}</Title>
      {subtitle && <SubTitle>{subtitle}</SubTitle>}
      {!showEvents && <SubTitle>{t("noEvents")}</SubTitle>}
      {show.pinned &&
        Array.from(pinnedDates).map((date) => (
          <Day key={date}>
            <Line />
            <Date>{format(date, "dd MMMM, eeee", { locale: timeLang })}</Date>
            {pinnedEvent.map((event) => {
              return (
                format(event.startDate, "yyyy-mm-dd") ===
                format(date, "yyyy-mm-dd") && (
                  <Event key={event.id} event={event} />
                )
              );
            })}
          </Day>
        ))}

      {show.upcomming &&
        Array.from(upcommingDates).map((date) => (
          <Day key={date}>
            <Line />
            <Date>{format(date, "dd MMMM, eeee", { locale: timeLang })}</Date>
            {upcommingEvents.map((event) => {
              return (
                format(event.startDate, "yyyy-mm-dd") ===
                format(date, "yyyy-mm-dd") && (
                  <Event key={event.id} event={event} />
                )
              );
            })}
          </Day>
        ))}

      {show.myEvents &&
        Array.from(myEventsDates).map((date) => (
          <Day key={date}>
            <Line />
            <Date>{format(date, "dd MMMM, eeee", { locale: timeLang })}</Date>
            {myEvents.map((event) => {
              return (
                format(event.startDate, "yyyy-mm-dd") ===
                format(date, "yyyy-mm-dd") && (
                  <MyEvent key={event.id} event={event} />
                )
              );
            })}
          </Day>
        ))}
      <img src={cycle} alt="Cycle" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 340px;
  border-left: 1px solid #e0e0e0;
  flex-shrink: 0;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 1600px) {
    width: 280px;
  }

  @media (max-width: 1200px) {
    border-left: none;
    width: 100%;
  }

  img {
    @media (max-width: 1200px) {
      width: 40%;
      align-self: center;
    }

    @media (max-width: 960px) {
      width: 50%;
      align-self: center;
    }

    @media (max-width: 780px) {
      width: 60%;
      align-self: center;
    }
  }
`;

const Title = styled(Heading3)`
  padding: 0px 30px;

  /* Primary Text */
  color: #303134;
`;

const SubTitle = styled(Heading3)`
  text-align: center;
  /* Primary Text */
  color: #303134;
`;

const Day = styled.div`
  position: relative;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 15px 0px;
  /* Tertiary Text */
  background: #d7d7d7;
`;

const Date = styled(Heading5)`
  position: absolute;
  left: 30px;
  top: 0px;
  background-color: #fff;
  padding: 2px 23px;

  /* Secondary Text */
  color: #717579;
`;

export default Schedule;
