import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { useAuth } from '../../providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import Button from '../../libs/reuse/components/Button';
import Input from '../../libs/reuse/components/Input';
import ErrorForm from '../../libs/reuse/components/ErrorForm';
import ButtonContained from '../../libs/reuse/components/ButtonContained';
import { facebookAuth, googleAuth } from '../../firebase-config';
import ButtonOutlined from '../../libs/reuse/components/ButtonOutlined';
import { Heading2, Heading5 } from '../../libs/reuse/components/texts/textStyles';
import { useLocation, useNavigate } from 'react-router';

function Login() {
    const { login, error, resetError, loginWithProvider } = useAuth();
    const { t } = useTranslation("auth")
    const navigate = useNavigate();
    const { accessFeatures } = useLocation().state ?? {};

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        resetError();
    }, []);

    const loginHandler = (e) => {
        e.preventDefault();
        login(email, password);
    };

    return (
        <Wrapper>
            <Header>
                {t("signIn")}
            </Header>
            {(accessFeatures || false) && <Features>
                <Heading5>{t("features")}</Heading5>
            </Features>}
            <Content onSubmit={loginHandler}>
                <Input
                    text={t("email")}
                    type="email"
                    value={email}
                    onChange={setEmail}
                    autoComplete="email"
                />
                <Input
                    text={t("password")}
                    type="password"
                    value={password}
                    onChange={setPassword}
                    autoComplete="current-password"
                    showEye={true}
                />
                {error && (
                    <ErrorForm
                        error={
                            t(error, { ns: "errors" }) || t("somethingWentWrong")
                        }
                    />
                )}
                <ForgotPassword onClick={() => navigate("/forgot-password")}>
                    {t("forgotPassword")}
                </ForgotPassword>
                <SubmitButton>
                    <ButtonContained
                        text={t("signIn")}
                        color="#6689A1"
                        type='submit'
                    />
                </SubmitButton>
            </Content>
            <Line />
            <Providers>
                <Button
                    iconName="google"
                    iconSize="35px"
                    onClick={() => loginWithProvider(googleAuth)}
                />
                <Button
                    iconName="facebook"
                    iconSize="35px"
                    onClick={() => loginWithProvider(facebookAuth)}
                />
            </Providers>
            <Text>{t("or")}</Text>
            <ButtonOutlined
                text={t("signUp")}
                color="#6689A1"
                onClick={() => navigate("/register")}
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    padding: 30px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    box-sizing: border-box;
    color: #303134;
  `;

const Header = styled(Heading2)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;

const Content = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 500px;
    justify-content: center;

    @media (max-width: 760px) {
        width: 100%;
    }
  `;

const ForgotPassword = styled.div`
    font-family: "Cuprum";
    align-self: end;
    cursor: pointer;
  `;

const Text = styled.div`
    font-family: "Cuprum";
  `;

const Line = styled.div`
    width: 50%;
    height: 1px;
    background-color: #d7d7d7;
  `;

const Providers = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
  `;

const Features = styled.div`
  margin: 20px;`

const SubmitButton = styled.div`
    align-self: center;
  `

export default Login;