import { useEffect, useState } from "react";

export function useEventFilters(events, setFilterResults) {
  // Add state for each filter
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [filterType, setFilterType] = useState("");
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");

  useEffect(() => {
    setFilterResults(
      events?.filter((event) => {
        let returnFlag = 1;
        if (name && !event.name.toLowerCase().includes(name.toLowerCase())) {
          returnFlag = 0;
        }
        if (
          location &&
          !event.location.name.toLowerCase().includes(location.toLowerCase())
        ) {
          returnFlag = 0;
        }
        if (
          description &&
          !event.description.toLowerCase().includes(description.toLowerCase())
        ) {
          returnFlag = 0;
        }
        if (link && !event.link.toLowerCase().includes(link.toLowerCase())) {
          returnFlag = 0;
        }
        if (from && event.endDate < new Date(from)) {
          returnFlag = 0;
        }
        if (to && event.startDate > new Date(to)) {
          returnFlag = 0;
        }
        if (filterType && !event.type[filterType]) {
          returnFlag = 0;
        }
        return returnFlag;
      })
    );
  }, [
    events,
    from,
    to,
    filterType,
    location,
    description,
    link,
    setFilterResults,
    name,
  ]);

  return {
    from,
    setFrom,
    to,
    setTo,
    filterType,
    setFilterType,
    name,
    setName,
    location,
    setLocation,
    description,
    setDescription,
    link,
    setLink,
  };
}
