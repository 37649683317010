import { Navigate } from "react-router";
import { useAuth } from "../../../providers/AuthProvider";
import { ADMIN, ORGANIZER, RACER, ANONYMOUS } from "../../../constants/roles";

function ProtectedRoute({ role, children }) {
  const { user } = useAuth();
  switch (role) {
    case ADMIN:
      if (user?.role !== ADMIN) return <Navigate to="/" replace />;
      break;
    case ORGANIZER:
      if (user?.role !== ADMIN && user?.role !== ORGANIZER)
        return <Navigate to="/" replace />;
      break;
    case RACER:
      if (
        user?.role !== ADMIN &&
        user?.role !== ORGANIZER &&
        user?.role !== RACER
      )
        return <Navigate to="/login" state={{ accessFeatures: true }} replace />;
      break;
    case ANONYMOUS:
      if (user)
        return <Navigate to="/" replace />;
      break;
    default:
      break;
  }

  return children;
}

export default ProtectedRoute;
