import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Body2, Subtitle } from "../../../libs/reuse/components/texts/textStyles";

function EventsSummary({ number }) {
  const { t } = useTranslation("calendar")
  return (
    <Wrapper>
      <New>
        <Body2>{number}</Body2>
        <Subtitle>{number > 1 ? t("events") : t("event")}</Subtitle>
      </New>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20%);

  @media (max-width: 480px) {
    display: none;
  }
`;

const New = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default EventsSummary;
